import auth from './auth/saga';
import tasks from './tasks/saga';
import projects from './projects/saga';
import dashboardProjects from './dashboardProjects/saga';
import dashboardTasks from './dashboardTasks/saga';
import pendingTasks from './pendingTasks/saga';
import pendingForms from './pendingForms/saga';
import inbox from './inbox/saga';
import activities from './activities/saga';
import campaigns from './campaigns/saga';

import { all, fork } from 'redux-saga/effects';

export default function* root() {
  yield all([
    fork(auth),
    fork(tasks),
    fork(projects),
    fork(inbox),
    fork(dashboardProjects),
    fork(dashboardTasks),
    fork(pendingTasks),
    fork(activities),
    fork(pendingForms),
    fork(campaigns),
  ]);
}
