import { Form, FormInstance } from 'antd';
import { forwardRef } from 'react';
import RichTextEditor, {
  RichTextEditorHandle,
  RichTextEditorProps,
} from './index';

interface RichTextEditorFormProps
  extends Omit<RichTextEditorProps, 'value' | 'defaultValue' | 'onChange'> {
  form: FormInstance;
  name: string;
  projectId?: string;
  includeMention?: boolean;
}

const RichTextEditorForm = forwardRef<
  RichTextEditorHandle,
  RichTextEditorFormProps
>(({ form, name, ...props }, ref) => {
  const value = Form.useWatch(name, { form });

  return (
    <RichTextEditor
      ref={ref}
      value={value}
      onChange={(newValue) => form.setFieldValue(name, newValue)}
      {...props}
    />
  );
});

export default RichTextEditorForm;
