import { IoAlertCircleOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

export default function Toast() {
  const navigate = useNavigate();

  return (
    <div className="sticky bottom-1 left-0 right-0 z-10 mx-auto h-2/6 w-11/12 rounded-2xl p-0.5 sm:h-[120px] md:w-8/12 ">
      <div className="flex w-full flex-col items-center justify-center space-y-3 rounded-[14px] border border-red-500 bg-red-50 px-5 py-3 sm:flex-row sm:justify-between sm:space-y-0">
        <div className="pb-0">
          <div className="flex flex-nowrap align-middle">
            <IoAlertCircleOutline
              className="mr-3 h-6 w-6 text-red-500"
              aria-hidden="true"
            />
            <p className="mb-1 text-[18px] font-bold text-red-500">
              Needs attention
            </p>
          </div>
          <p className="flex items-center justify-center text-[14px] text-black">
            Our team needs a few pieces of information/feedback from you; please
            take a moment to provide it.
          </p>
        </div>
        <div
          onClick={() => navigate('/pending')}
          className="flex h-10 w-[120px] cursor-pointer items-center justify-center whitespace-nowrap rounded-md bg-red-500 px-4 text-[16px] text-white transition-all hover:bg-red-700"
        >
          Fix it now
        </div>
      </div>
    </div>
  );
}
