import { Skeleton } from 'antd';
import React, { FC } from 'react';
import { ProjectResponse } from 'src/types';
import {
  convertSnakeCaseToTitleCase,
  parseDateToUSFormat,
  priceFormatter,
} from 'src/utils/stringHelpers';
import { RxQuestionMarkCircled } from 'react-icons/rx';
import { useAppTour } from 'src/context/appTourContext';

interface AdvertisingProps {
  data?: ProjectResponse | null;
  loading?: boolean;
  showTourButton?: boolean;
}

interface MapPlatforms {
  [key: string]: { src: string; alt: string };
}

const mapPlatforms: MapPlatforms = {
  amazon: { src: '/assets/Amazon.svg', alt: 'Amazon Logo' },
  facebook: { src: '/assets/Facebook.svg', alt: 'Facebook Logo' },
  google: { src: '/assets/Google.svg', alt: 'Google Logo' },
  instagram: { src: '/assets/Instagram.svg', alt: 'Instagram Logo' },
  linkedin: { src: '/assets/Linkedin.svg', alt: 'Linkedin Logo' },
  ott: { src: '/assets/Ott.svg', alt: 'Ott Logo' },
  tiktok: { src: '/assets/Tiktok.svg', alt: 'Tiktok Logo' },
  youtube: { src: '/assets/Youtube.svg', alt: 'Youtube Logo' },
};

const Advertising: FC<AdvertisingProps> = ({
  data,
  loading,
  showTourButton = false,
}) => {
  const hasAdvertising = !!data?.advertising_platforms?.length;
  const { setOpenTour } = useAppTour();

  return (
    <div className="relative mb-10 rounded-2xl border-[1px] border-[#4719FF] bg-[#4719FF] bg-opacity-[10%] p-5 text-[#4719FF]">
      {loading ? (
        <div className="w-[300px]">
          <Skeleton className="my-[20px]" />
        </div>
      ) : (
        <div className="flex flex-col gap-[18px]">
          {showTourButton && (
            <div className="absolute right-2 top-2">
              <button
                type="button"
                onClick={() => {
                  setOpenTour(true, 6);
                }}
              >
                <RxQuestionMarkCircled className="text-2xl" />
              </button>
            </div>
          )}

          <div
            className={`grid gap-x-2 gap-y-8 md:gap-x-8 ${
              hasAdvertising
                ? 'grid-cols-2 md:grid-cols-4'
                : 'grid-cols-2 md:grid-cols-3'
            }`}
          >
            {hasAdvertising && (
              <div>
                <div className="text-xs font-bold">Active platforms</div>
                <div className="mt-2 flex flex-wrap items-start gap-[5px]">
                  {data?.advertising_platforms?.map((platform) => {
                    const platformObj = mapPlatforms[platform];

                    if (!platformObj) return undefined;

                    return (
                      <div
                        className="aspect-square basis-12 rounded-[10px] bg-white p-[8px]"
                        key={platform}
                      >
                        <img src={platformObj.src} alt={platformObj.alt} />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            <div
              className={`${hasAdvertising ? '' : 'col-span-2 md:col-span-1'}`}
            >
              {data?.conversion_goals?.length ? (
                <>
                  <div className="text-xs font-bold">Goals</div>

                  <div className="mt-2 space-y-1 text-sm">
                    {data?.conversion_goals?.map((item, i) => (
                      <div key={i}>
                        <span className="inline-block rounded-[50px] border-[1px] border-[#4719ff33] bg-[#4719ff1a] px-[8px] py-[2px]">
                          {convertSnakeCaseToTitleCase(item)}
                        </span>
                      </div>
                    ))}
                  </div>
                </>
              ) : undefined}
            </div>

            <div>
              <div className="text-xs font-bold">Start date</div>
              <p className="mt-1 text-lg font-bold md:text-2xl">
                {parseDateToUSFormat(data?.start_date)}
              </p>
            </div>

            <div>
              <div className="text-xs font-bold">Monthly budget</div>
              <p className="mt-1 text-lg font-bold md:text-2xl">
                {data?.monthly_budget
                  ? priceFormatter(data?.monthly_budget / 100)
                  : 0}
              </p>
            </div>
          </div>
          {data?.strategy_goals && (
            <div className="text-xs">
              <p className="font-bold">Strategy goals (Monthly):</p>
              <p>{data?.strategy_goals}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Advertising;
