import { AxiosResponse } from 'axios';
import { call, put, StrictEffect, takeLatest } from 'redux-saga/effects';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';

import {
  getInbox,
  setInboxData,
  setInboxError,
  setInboxLoading,
} from './reducer';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';

const { get } = apiRequests;

function* getInboxSagaListener(action: any) {
  try {
    yield put(setInboxLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.INBOX, action.payload);
    yield put(setInboxData(res.data));
  } catch (error) {
    yield put(setInboxError(error));
    asyncErrorHandler(error);
  }
}

function* InboxSaga(): Generator<StrictEffect> {
  yield takeLatest(getInbox.type, getInboxSagaListener);
}

export default InboxSaga;
