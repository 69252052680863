/* eslint-disable react-hooks/exhaustive-deps */
import { List, Skeleton } from 'antd';
import { TbSubtask, TbUserCircle } from 'react-icons/tb';
import { FC, useCallback, useEffect } from 'react';
import Box from '../Box';

import { useDispatch } from 'react-redux';
import StatusTag from '../StatusTag';
import { getQueryValue, parseDateToUSFormat } from 'src/utils/stringHelpers';
import TaskDrawer from '../TaskDrawer';
import { useLocation, useNavigate } from 'react-router-dom';
import { TaskResponse } from 'src/types';
import { IoFolderOpen } from 'react-icons/io5';
import { IoIosArrowForward } from 'react-icons/io';
import { BsCalendarDate } from 'react-icons/bs';
import { RxQuestionMarkCircled } from 'react-icons/rx';
import { useAppTour } from '../../context/appTourContext';

interface TaskProps {
  className?: string;
  params?: any;
  page?: 'project' | 'dashboard';
  loading: boolean;
  data: TaskResponse[] | undefined;
  onDispatch: any;
  hidden?: boolean;
  showTourButton?: boolean;
  includeCompleted?: boolean;
}

const Task: FC<TaskProps> = ({
  className = '',
  params = {},
  data,
  loading,
  onDispatch,
  hidden = false,
  showTourButton = false,
  includeCompleted = false,
}) => {
  const { setOpenTour } = useAppTour();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { search, pathname } = useLocation();

  const taskId = getQueryValue(search, 'task_id');

  const closeTask = () => {
    navigate(`${pathname}`, { preventScrollReset: true });
  };

  const fetchTasks = useCallback(() => {
    dispatch(
      onDispatch({
        'filters[task_status]': [
          'todo',
          'in_progress',
          'waiting_approval',
          'working_on_now',
          'pending',
          'approved',
          'needs_attention',
          ...(includeCompleted ? ['completed'] : []),
        ],
        ...params,
      })
    );
  }, [includeCompleted]);

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  if (hidden) {
    return <></>;
  }

  return (
    <div className={className}>
      <div className="mb-2 flex gap-2 px-1 text-blue-600">
        <TbSubtask className="text-2xl" />
        <span className="flex-auto font-bold">Tasks</span>
        {showTourButton && (
          <button
            type="button"
            onClick={() => {
              setOpenTour(true, 9);
            }}
          >
            <RxQuestionMarkCircled className="text-2xl" />
          </button>
        )}
      </div>

      <div className="h-[300px]">
        <Box>
          {loading ? (
            <Skeleton className="pt-[30px]" />
          ) : (
            <List
              itemLayout="horizontal"
              dataSource={data ?? []}
              renderItem={(task) => (
                <List.Item
                  key={task?.uuid}
                  onClick={() =>
                    navigate(`?task_id=${task?.uuid}`, {
                      preventScrollReset: true,
                    })
                  }
                  className="cursor-pointer"
                >
                  <div className="w-full">
                    <p className="pb-2 text-[14px] md:pb-1">
                      {task?.task_title}
                    </p>

                    <div className="flex items-start justify-between gap-3">
                      <div className="flex flex-wrap">
                        {task?.task_end_date && (
                          <>
                            <div className="flex basis-full items-center gap-[4px] text-blue-500 md:basis-auto">
                              <BsCalendarDate className="text-[18px]" />
                              <span>
                                {parseDateToUSFormat(task?.task_end_date)}
                              </span>
                            </div>
                            <span className="mx-2 hidden md:inline">-</span>
                          </>
                        )}

                        <div className="basis-full break-all text-blue-500 md:basis-auto">
                          <TbUserCircle className="mr-1 inline-block text-lg" />
                          <span className="align-middle">
                            {task?.customer_business_name}
                          </span>
                        </div>

                        <span className="mx-2 hidden md:inline">-</span>

                        <div className="basis-full text-blue-500 md:basis-auto">
                          <IoFolderOpen className="mr-1 inline-block text-lg" />
                          <span className="align-middle">
                            {task?.project_title}
                          </span>
                        </div>
                      </div>

                      <div className="whitespace-nowrap">
                        <StatusTag value={task?.task_status} />
                        <IoIosArrowForward className="ml-1 inline-block text-lg text-gray-600" />
                      </div>
                    </div>
                  </div>
                </List.Item>
              )}
            />
          )}
        </Box>
      </div>
      <TaskDrawer open={taskId} onClose={closeTask} fetchTasks={fetchTasks} />
    </div>
  );
};

export default Task;
