import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Input,
  Select,
  Skeleton,
  message,
  Modal,
} from 'antd';
import { FC, useMemo, useState } from 'react';
import Comment from '../Comment';
import useTaskDrawer from './useTaskDrawer';
import apiRoutes from 'src/utils/apiRoutes';
import MultipleSelectSearch from '../MultipleSelectSearch';
import {
  convertArrToCommaSeparatedString,
  parseDateToUSFormat,
  removeImage,
} from 'src/utils/stringHelpers';
import dayjs from 'dayjs';

import getTaskStatusOptions from 'src/utils/getTaskStatusOptions';
import StatusActions from '../StatusActions';
import FeedbackModal from '../FeedbackModal';
import ApprovalCountDown from '../ApprovalCountdown';
import { CheckOutlined } from '@ant-design/icons';
import UploadDragger from '../FileUpload/UploadDragger';
import RichTextEditorForm from '../RichTextEditor/RichTextEditorForm';
import AxiosErrorResult from '../AxiosErrorResult';

interface TaskDrawerProps {
  open: any;
  onClose: any;
  fetchTasks: () => void;
}

const TaskDrawer: FC<TaskDrawerProps> = ({ open, onClose, fetchTasks }) => {
  const {
    state,
    uploads,
    form,
    isCreateTask,
    editorRef,
    setState,
    handleSave,
    setUploads,
    downloadAllAssets,
    resetForm,
  } = useTaskDrawer();
  const { loading, data, saving, axiosError } = state;

  const [enableDrawerKeyboard, setEnableDrawerKeyboard] = useState(true);
  const [hasFileLoading, setHasFileLoading] = useState(false);
  const [loadingImages, setLoadingImages] = useState(0);

  const showConfirmCloseTask = () => {
    Modal.confirm({
      title: 'Do you want to close thi task?',
      content:
        'You still have uploads pending, if you close this task now any changes or uploads made will be lost.',
      onOk() {
        onClose();
        uploads.map((item) => item.abortController?.abort());
        setHasFileLoading(false);
      },
      okText: 'Yes, cancel it',
      okType: 'danger',
      cancelText: 'No',
    });
  };

  const projectId = Form.useWatch('project_id', { form });

  const safeCloseTask = () => {
    if (!isCreateTask && hasFileLoading) {
      showConfirmCloseTask();
    } else {
      onClose();
    }
  };

  const optionsStatus = useMemo(() => {
    return getTaskStatusOptions(data?.status, !!data?.approval_target);
  }, [data?.status, data?.approval_target]);

  return (
    <Drawer
      className="task-drawer"
      destroyOnClose
      title={!axiosError ? 'Task request form' : 'Task not found'}
      keyboard={enableDrawerKeyboard}
      onClose={safeCloseTask}
      afterOpenChange={(opened) => {
        if (!opened) {
          resetForm();
        }
      }}
      open={!!open}
      bodyStyle={{ paddingBottom: 10 }}
      extra={
        isCreateTask ? (
          <div className="space-x-4">
            <Button onClick={onClose} type="link" shape="round">
              Cancel
            </Button>
            <Button
              disabled={hasFileLoading || loadingImages > 0}
              loading={saving}
              onClick={async () => {
                await handleSave();
                fetchTasks();
                if (isCreateTask) {
                  onClose();
                }
              }}
              type="primary"
              shape="round"
            >
              Save
            </Button>
          </div>
        ) : undefined
      }
      footer={
        isCreateTask ? undefined : (
          <div className="w-full text-right text-[12px] text-gray-400">
            <p>ID #{data?.id}</p>
            <p>
              Created by {data?.creator?.name} on{' '}
              {parseDateToUSFormat(data?.created_at)} (
              {dayjs(data?.created_at).fromNow()})
            </p>
          </div>
        )
      }
    >
      {loading ? (
        <Skeleton />
      ) : (isCreateTask || state.data) && !axiosError ? (
        <>
          {!isCreateTask && data?.approval_target && (
            <StatusActions
              selected={state?.approval_status}
              onChange={async (value: any) => {
                if (saving || loading) {
                  return;
                }

                const showFeedbackModal = value !== 'approved';

                setState((prevState: any) => ({
                  ...prevState,
                  approval_status: value,
                  showFeedbackModal,
                }));
                if (!showFeedbackModal) {
                  await handleSave({
                    approval_status: 'approved',
                  });
                  message.success('Task approved.');
                  fetchTasks();
                  onClose();
                }
              }}
            />
          )}

          {!isCreateTask &&
            !data?.approval_target &&
            (data?.status === 'waiting_approval' ||
              data?.status === 'pending') && (
              <Button
                className="btn-green btn-icon-left-md mb-6 md:!text-left"
                icon={<CheckOutlined className="text-lg" />}
                disabled={saving || loading}
                size="large"
                block
                onClick={async () => {
                  const newStatus =
                    data.status === 'waiting_approval'
                      ? 'approved'
                      : 'completed';

                  setState((prevState: any) => ({
                    ...prevState,
                    status: newStatus,
                  }));

                  await handleSave({ status: newStatus });

                  message.success(
                    newStatus === 'approved'
                      ? 'Task approved.'
                      : 'Task completed'
                  );

                  fetchTasks();

                  onClose();
                }}
              >
                Click here to{' '}
                {data.status === 'waiting_approval' ? 'approve' : 'complete'}{' '}
                this task
              </Button>
            )}

          <FeedbackModal
            loading={saving || loading}
            onClose={() => {
              setState((prevState: any) => ({
                ...prevState,
                showFeedbackModal: false,
                approval_status: undefined,
              }));
              form.setFieldValue('status', data?.status);
            }}
            visible={state?.showFeedbackModal}
            onConfirm={async (values: any) => {
              values.approval_status = state?.approval_status;
              await handleSave(values);
              message.success('Updated task status.');
              fetchTasks();
              onClose();
            }}
          />
          <Form
            className="default-form"
            form={form}
            size="large"
            layout="vertical"
          >
            <div>
              <div className={isCreateTask ? '' : 'read-only'}>
                <Form.Item
                  className="w-full"
                  name="title"
                  label="Task name"
                  rules={[
                    { required: true, message: 'Please enter the task name' },
                  ]}
                >
                  <Input placeholder="Give your task a name" bordered={false} />
                </Form.Item>

                {isCreateTask ? (
                  <MultipleSelectSearch
                    defaultParams={{
                      'filters[projects.status]': [
                        'paused',
                        'waiting_to_launch',
                        'in_progress',
                        'active',
                      ],
                    }}
                    searchKey="search_term"
                    labelKeys={[
                      'title',
                      'customer.business_name',
                      'customer.abbreviation',
                    ]}
                    api={apiRoutes.PROJECTS}
                    className="w-full"
                    name="project_id"
                    label="Project"
                    placeholder="Select a project"
                    initialOption={data?.project}
                    rules={[
                      { required: true, message: 'Please select a project' },
                    ]}
                    render={(row: any) => {
                      return row?.title;
                    }}
                  />
                ) : (
                  <Form.Item className="w-full" label="Project" rules={[]}>
                    <Input
                      placeholder="Please enter the title"
                      bordered={false}
                      value={data?.project?.title}
                    />
                  </Form.Item>
                )}
              </div>
              {!isCreateTask && (
                <Form.Item
                  name="status"
                  label="Status"
                  className={optionsStatus.length > 1 ? '' : 'read-only'}
                >
                  <Select
                    placeholder="Please select a status"
                    className=" w-full"
                    bordered={false}
                    options={optionsStatus}
                    disabled={saving || loading || optionsStatus.length <= 1}
                    onChange={async (status) => {
                      if (isCreateTask) return;

                      if (data?.approval_target) {
                        const showFeedbackModal = status !== 'approved';

                        setState((prevState: any) => ({
                          ...prevState,
                          approval_status: status,
                          showFeedbackModal,
                        }));

                        if (showFeedbackModal) {
                          return;
                        }

                        await handleSave({ approval_status: status });
                      } else {
                        setState((prevState: any) => ({
                          ...prevState,
                          status,
                        }));

                        await handleSave({ status });
                      }

                      if (status === 'completed') {
                        message.success('Task completed.');
                      } else {
                        message.success('Task approved.');
                      }

                      fetchTasks();
                      onClose();
                    }}
                  />
                </Form.Item>
              )}
              {!isCreateTask && (
                <div className="read-only">
                  {data &&
                    data?.related_users?.filter(
                      (user) => user?.pivot?.type !== 'follower'
                    )?.length > 0 && (
                      <Form.Item className="w-full" label="Assignee">
                        <Input
                          placeholder="Add Assignee"
                          value={convertArrToCommaSeparatedString(
                            data?.related_users
                              ?.filter(
                                (user) => user?.pivot?.type !== 'follower'
                              )
                              .map((user) => user?.name) ?? []
                          )}
                          bordered={false}
                        />
                      </Form.Item>
                    )}
                  <div className="flex flex-wrap gap-6">
                    {data &&
                      data?.related_users?.filter(
                        (user) => user?.pivot?.type === 'follower'
                      )?.length > 0 && (
                        <Form.Item
                          className="basis-full md:flex-1"
                          label="Followers"
                        >
                          <Input
                            placeholder="Add followers"
                            bordered={false}
                            value={convertArrToCommaSeparatedString(
                              data?.related_users
                                ?.filter(
                                  (user) => user?.pivot?.type === 'follower'
                                )
                                .map((user) => user?.name) ?? []
                            )}
                          />
                        </Form.Item>
                      )}
                    {data?.approval_target && (
                      <div className="flex-1">
                        <ApprovalCountDown
                          onCancel={() => {
                            setState((prevState: any) => ({
                              ...prevState,
                              approval_status: 'cancelled',
                              showFeedbackModal: true,
                            }));
                          }}
                          approval_target={data?.approval_target}
                        />
                      </div>
                    )}
                  </div>
                  {(data?.start_date || data?.end_date) && (
                    <Form.Item className="w-full" label="Due date">
                      <DatePicker.RangePicker
                        bordered={false}
                        format="MM/DD/YYYY"
                        value={[
                          data?.start_date ? dayjs(data?.start_date) : null,
                          data?.end_date ? dayjs(data?.end_date) : null,
                        ]}
                        className="w-full"
                        getPopupContainer={(trigger) => trigger.parentElement!}
                      />
                    </Form.Item>
                  )}
                </div>
              )}
              <Form.Item
                className="w-full"
                name="description"
                label="Description"
              >
                <RichTextEditorForm
                  key={open}
                  ref={editorRef}
                  form={form}
                  name="description"
                  placeholder="Describe in detail what your requirements and needs are."
                  projectId={projectId}
                  readonly={!isCreateTask}
                  includeToolbar={isCreateTask}
                  collaborativeEditor={{
                    defaultValue: data?.description_delta,
                  }}
                  onImageUploader={(upload) => {
                    setUploads((prev) => [...prev, upload]);
                  }}
                  onLoadingImage={setLoadingImages}
                  onImageModalChanged={(open) => setEnableDrawerKeyboard(!open)}
                />
              </Form.Item>
            </div>
          </Form>

          <UploadDragger
            uploads={uploads}
            setLoading={setHasFileLoading}
            setUploads={setUploads}
            showDownloadIcon={true}
            showRemoveIcon={isCreateTask}
            showDownloadAll={!isCreateTask}
            onDownloadAll={downloadAllAssets}
            onRequest={
              isCreateTask
                ? undefined
                : async ({ uuid }) => {
                    const data = uploads
                      .map((item) => item.response?.uuid)
                      .filter((uuid) => uuid);

                    const response = await handleSave({
                      uploads: [uuid, ...data],
                    });

                    return (
                      response &&
                      response.data.data.uploads.find(
                        (item: any) => item.uuid === uuid
                      )
                    );
                  }
            }
            onRemove={(upload) => {
              form.setFieldValue(
                'description',
                removeImage(
                  form.getFieldValue('description'),
                  upload.response?.url || ''
                )
              );
            }}
          />

          {!isCreateTask && (
            <div className="mt-3">
              <Comment
                url={`${apiRoutes.PROJECT_TASKS}/${open}/comments`}
                projectUuid={data?.project?.uuid ?? ''}
                onCommentsLoaded={() => {
                  const drawerBody = document.querySelector('.ant-drawer-body');
                  drawerBody?.scrollTo(0, drawerBody?.scrollHeight);
                }}
                onFinishSubmit={(_, images) => {
                  setUploads((old) => [...old, ...images]);
                }}
                onFilesDelete={(listUuid) => {
                  setUploads((old) =>
                    old.filter(
                      (item) =>
                        !listUuid.includes(item.response?.uuid ?? item.uid)
                    )
                  );
                }}
              />
            </div>
          )}
        </>
      ) : undefined}

      {axiosError ? <AxiosErrorResult error={axiosError} /> : undefined}
    </Drawer>
  );
};

export default TaskDrawer;
