import { Select } from 'antd';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthStateType, changeCustomer } from 'src/store/auth/reducer';

interface CompanySelectorProps {}

const CompanySelector: FC<CompanySelectorProps> = () => {
  const { user }: AuthStateType = useSelector(
    (globalState: any) => globalState.auth
  );

  const dispatch = useDispatch();

  const updateUserCompany = async (uuid: string) => {
    dispatch(changeCustomer(uuid));
  };

  if (user?.contact?.customers?.length === 1) {
    return null;
  }

  return (
    <Select
      size="large"
      className="header__select max-w-[280px] !rounded-md"
      defaultValue={user?.contact?.customer?.uuid}
      onChange={(e) => {
        updateUserCompany(e);
      }}
    >
      {user?.contact?.customers?.map((el: any) => (
        <Select.Option key={el?.uuid} value={el?.uuid}>
          {el?.abbreviation}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CompanySelector;
