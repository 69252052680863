import { Alert, Skeleton } from 'antd';
import React, { useEffect } from 'react';
import Advertising from 'src/components/Advertising';
import { useDispatch, useSelector } from 'react-redux';
import { getCampaigns } from '../../store/campaigns/reducer';
import { AuthStateType } from 'src/store/auth/reducer';

const Campaigns: React.FC = () => {
  const campaigns = useSelector((globalState: any) => globalState.campaigns);

  const auth: AuthStateType = useSelector(
    (globalState: any) => globalState.auth
  );

  const dispatch = useDispatch();

  const advertisingData = campaigns.data?.data;

  useEffect(() => {
    dispatch(getCampaigns());
  }, [dispatch]);

  const { customer } = auth.user?.contact ?? {};

  return (
    <div>
      {advertisingData?.length !== 0 && (
        <Advertising
          loading={campaigns.loading && !advertisingData}
          data={advertisingData?.[0]}
        />
      )}

      {auth.loading ? (
        <Skeleton />
      ) : (
        <>
          {customer && customer.dashboard_link_1 && (
            <div className="mt-4 hidden min-h-[300px] w-full rounded-lg bg-white px-2 py-4 shadow-xl shadow-blue-500/20 md:block">
              <iframe
                src={customer.dashboard_link_1}
                title="Dashhboard 1"
                className="h-[980px] w-full"
              ></iframe>
            </div>
          )}

          {customer && customer.dashboard_link_2 && (
            <div className="mt-4 min-h-[300px] w-full rounded-lg bg-white px-2 py-4 shadow-xl shadow-blue-500/20 md:hidden">
              <iframe
                src={customer.dashboard_link_2}
                title="Dashhboard 2"
                className="h-[980px] w-full"
              ></iframe>
            </div>
          )}

          {customer && !customer.dashboard_link_1 && (
            <div className="hidden md:block">
              <Alert
                description="Our team is updating the report and it will be available soon."
                type="info"
                showIcon
              />
            </div>
          )}

          {customer && !customer.dashboard_link_2 && (
            <div className="md:hidden">
              <Alert
                description="Our team is updating the report and it will be available soon."
                type="info"
                showIcon
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Campaigns;
