import { AxiosResponse } from 'axios';
import { call, put, StrictEffect, takeLatest } from 'redux-saga/effects';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';

import {
  getDashboardTasks,
  setDashboardTasksData,
  setDashboardTasksError,
  setDashboardTasksLoading,
} from './reducer';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import { sortTaskByDueDate, sortTasksByStatus } from 'src/utils/arrayHelpers';

const { get } = apiRequests;

function* getDashboardTasksSagaListener(action: any) {
  try {
    yield put(setDashboardTasksLoading());

    const res: AxiosResponse = yield call(
      get,
      apiRoutes.PROJECT_TASKS,
      action.payload
    );

    yield put(
      setDashboardTasksData({
        ...res.data,
        data: sortTasksByStatus(sortTaskByDueDate(res.data.data)),
      })
    );
  } catch (error) {
    yield put(setDashboardTasksError(error));
    asyncErrorHandler(error);
  }
}

function* dashboardTasksSaga(): Generator<StrictEffect> {
  yield takeLatest(getDashboardTasks.type, getDashboardTasksSagaListener);
}

export default dashboardTasksSaga;
