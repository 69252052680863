/* eslint-disable react-hooks/exhaustive-deps */
import { List, Skeleton, Space } from 'antd';
import { IoMailOutline } from 'react-icons/io5';
import InfiniteScroll from 'react-infinite-scroll-component';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InboxStateType, getInbox } from 'src/store/inbox/reducer';
import { mentionCommentParser } from 'src/utils/stringHelpers';
import UserAvatar from '../UserAvatar';
import { useNavigate } from 'react-router-dom';
import RichTextEditor from '../RichTextEditor';
import { RxQuestionMarkCircled } from 'react-icons/rx';
import { useAppTour } from '../../context/appTourContext';
import { deletedMessage } from '../CommentMessage';

interface InboxType {
  loading?: boolean;
}

const Inbox: React.FC<InboxType> = ({ loading: loading1 }) => {
  const inbox: InboxStateType = useSelector(
    (globalState: any) => globalState.inbox
  );
  const { loading, data } = inbox;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setOpenTour } = useAppTour();

  useEffect(() => {
    dispatch(getInbox());
  }, []);

  return (
    <Space direction="vertical" className="w-full">
      <div className="flex gap-2 px-1 text-blue-600">
        <IoMailOutline className="text-2xl" />
        <span className="flex-auto font-bold">My inbox</span>
        <button
          type="button"
          onClick={() => {
            setOpenTour(true, 7);
          }}
        >
          <RxQuestionMarkCircled className="text-2xl" />
        </button>
      </div>
      <div className="h-[300px] w-full overflow-auto rounded-2xl bg-white px-4 py-0 shadow-xl shadow-blue-500/20">
        {(loading && !data) || loading1 ? (
          <Skeleton className="mt-[20px]" />
        ) : (
          <>
            <InfiniteScroll
              dataLength={data?.data?.length ?? 0}
              next={() => null}
              hasMore={false}
              loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
              // endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
              scrollableTarget="scrollableDiv"
            >
              <List
                dataSource={data?.data}
                renderItem={(item) => (
                  <List.Item
                    key={item?.uuid}
                    className="cursor-pointer"
                    onClick={() => {
                      if (item.location?.type === 'project') {
                        navigate(
                          `/projects/${item?.location?.uuid}?target_comment_id=${item?.uuid}`
                        );
                      } else if (item.location?.type === 'task') {
                        navigate(
                          `/dashboard?task_id=${item?.location?.uuid}&target_comment_id=${item?.uuid}`
                        );
                      }
                    }}
                  >
                    <div className="flex gap-[20px]">
                      <div>
                        <UserAvatar user={item?.creator} />
                      </div>
                      <div className="flex flex-col">
                        <p>{item?.creator?.name}</p>
                        <RichTextEditor
                          className="comment-styles text-gray-400"
                          defaultValue={
                            !item.deleted_at
                              ? mentionCommentParser(item.text)
                              : deletedMessage
                          }
                          readonly
                          noContainer
                        />
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </>
        )}
      </div>
    </Space>
  );
};

export default Inbox;
