/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Advertising from 'src/components/Advertising';
import Inbox from 'src/components/Inbox';
import Projects from 'src/components/ProjectList';
import Activity from 'src/components/Activity';
import Task from 'src/components/Task';
import {
  TasksStateType,
  getDashboardTasks,
} from 'src/store/dashboardTasks/reducer';
import { getCampaigns } from 'src/store/campaigns/reducer';
import { useAppTour } from 'src/context/appTourContext';

export default function Dashboard() {
  const { user } = useSelector((globalState: any) => globalState.auth);
  const dashboardTasks: TasksStateType = useSelector(
    (globalState: any) => globalState.dashboardTasks
  );
  const campaigns = useSelector((globalState: any) => globalState.campaigns);
  const dashboard = useSelector((globalState: any) => globalState.dashboard);
  const dispatch = useDispatch();
  const { setStepTarget, hiddenStep, unhiddenStep } = useAppTour();

  const advertisingData = campaigns.data?.data;

  useEffect(() => {
    dispatch(getCampaigns());
  }, []);

  useEffect(() => {
    if (advertisingData?.length === 0) {
      hiddenStep(6);
    } else {
      unhiddenStep(6);
    }
  }, [advertisingData?.length]);

  return (
    <div>
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
        <div className="mb-8 lg:mb-8">
          <p className="text-lg font-light text-blue-700 lg:text-3xl">
            Welcome to Lionshare
          </p>
          <p className="text-sm font-bold text-blue-700 lg:text-base">
            {user?.name}
          </p>
        </div>
        <div className="col-span-2">
          {advertisingData?.length !== 0 && (
            <div ref={(node) => setStepTarget(6, node)}>
              <Advertising
                loading={campaigns.loading && !advertisingData}
                data={advertisingData?.[0]}
                showTourButton
              />
            </div>
          )}
        </div>
      </div>

      <div className="mt-8 grid grid-cols-1 gap-12 lg:mt-0 lg:grid-cols-3">
        <div className="col-span-1 lg:col-span-2">
          <div className="grid grid-cols-1 gap-12 lg:grid-cols-2">
            <div ref={(node) => setStepTarget(7, node)}>
              <Inbox loading={dashboard.loading} />
            </div>
            <div ref={(node) => setStepTarget(8, node)}>
              <Projects loading={dashboard.loading} />
            </div>
            <div
              className="col-span-1 lg:col-span-2"
              ref={(node) => setStepTarget(9, node)}
            >
              <Task
                params={{
                  'filters[task_status]': [
                    'todo',
                    'in_progress',
                    'waiting_approval',
                    'working_on_now',
                    'pending',
                    'approved',
                    'needs_attention',
                  ],
                }}
                onDispatch={getDashboardTasks}
                loading={
                  (dashboardTasks.loading && !dashboardTasks.data) ||
                  dashboard.loading
                }
                data={dashboardTasks.data?.data?.filter(
                  (task) =>
                    task?.customer_uuid === user?.contact?.customer?.uuid
                )}
                showTourButton
              />
            </div>
          </div>
        </div>
        <div className="relative">
          <div
            ref={(node) => setStepTarget(10, node)}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: 332,
            }}
          ></div>
          <Activity loading={dashboard.loading} />
        </div>
      </div>
    </div>
  );
}
