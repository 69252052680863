/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { TbAlertCircle } from 'react-icons/tb';
import Forms from '../../components/Forms';
import { useDispatch, useSelector } from 'react-redux';
import { TasksStateType } from 'src/store/tasks/reducer';
import Task from 'src/components/Task';
import { getPendingTasks } from 'src/store/pendingTasks/reducer';
import { getPendingForms } from 'src/store/pendingForms/reducer';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from 'antd';

const Pending: React.FC = () => {
  const navigate = useNavigate();

  const pendingTasks: TasksStateType = useSelector(
    (globalState: any) => globalState.pendingTasks
  );
  const pendingForms = useSelector(
    (globalState: any) => globalState.pendingForms
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      // @ts-ignore
      getPendingForms({
        'filters[forms_answers.status][]': 'pending',
      })
    );
  }, []);

  useEffect(() => {
    if (
      !pendingTasks.loading &&
      pendingTasks.data &&
      !pendingTasks.data.data.length &&
      !pendingForms.loading &&
      pendingForms.data &&
      !pendingForms.data.data.length
    ) {
      navigate('/dashboard', { replace: true });
    }
  }, [
    pendingTasks.loading,
    pendingTasks.data,
    pendingForms.loading,
    pendingForms.data,
  ]);

  return (
    <>
      {pendingTasks.data?.data?.length || pendingForms.data?.data?.length ? (
        <div className="mb-8 flex flex-col space-y-3 rounded-2xl border border-red-500 bg-red-50 px-5 pb-3">
          <div className="pb-0 pt-3">
            <div className="flex flex-nowrap align-middle">
              <TbAlertCircle
                className="mr-3 h-8 w-8 text-red-500"
                aria-hidden="true"
              />
              <p className="mb-1 text-[24px] font-bold text-red-500">
                Needs attention
              </p>
            </div>
            <p className="flex items-center text-[14px] text-black">
              We need your help with the items listed below. Please click to
              open and complete them for us.
            </p>
          </div>
          <div className="text-[12px]">
            <p className="font-bold text-gray-500">Attention</p>
            <p className="text-gray-500">
              Every time you miss a deadline for any of the items above, it will
              affect the deadline of your project as shown below:
            </p>
            <ul>
              <li>
                <p className="text-gray-500">
                  1-2 days = 1 week delay on your project
                </p>
              </li>
              <li>
                <p className="text-gray-500">
                  3-4 days = 2 week delay on your project
                </p>
              </li>
              <li>
                <p className="text-gray-500">
                  5-7 days = 3 week delay on your project
                </p>
              </li>
              <p className="font-bold text-gray-500">
                If there is a delay of 8+ days, your project will be paused, and
                a call should be scheduled to readjust the project timeline.
              </p>
            </ul>
          </div>
        </div>
      ) : undefined}

      <div className="flex flex-wrap gap-8">
        <Task
          className="flex-1 basis-full lg:basis-0"
          data={pendingTasks.data?.data}
          loading={pendingTasks.loading && !pendingTasks.data}
          onDispatch={getPendingTasks}
          page="project"
          params={{
            'filters[task_status]': ['waiting_approval', 'pending'],
          }}
          hidden={
            pendingForms.loading ||
            pendingTasks.loading ||
            !pendingTasks.data?.data?.length
          }
        />

        {pendingForms.loading || pendingTasks.loading ? (
          <>
            <Skeleton className="flex-1 pt-[30px]" />
            <Skeleton className="flex-1 pt-[30px]" />
          </>
        ) : (
          <>
            {pendingForms.data?.data?.length ? (
              <Forms
                className="flex-1 basis-full lg:basis-0"
                loading={pendingForms.loading && !pendingForms.data}
                data={pendingForms.data?.data}
              />
            ) : undefined}
          </>
        )}
      </div>
    </>
  );
};

export default Pending;
