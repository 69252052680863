import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { CommentResponse, UserResponse } from 'src/types';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import type SimpleBar from 'simplebar-react';
import { RichTextEditorHandle } from '../RichTextEditor';

type ScrollTypes =
  | 'initialScroll'
  | 'lastScrollPosition'
  | 'newComment'
  | 'newReply'
  | null;

interface CommentProps {
  url: string;
  onCommentsLoaded: any;
  onInitialLoaded?: () => void;
  onFilesDelete?: (uuid: string[]) => void;
}

const PAGE_SIZE = 100;

const getParams = (pageNumber: number) => ({
  'page[size]': PAGE_SIZE,
  'page[number]': pageNumber,
  'sort[by]': 'created_at',
  'sort[direction]': 'desc',
});

const useComments = ({
  url,
  onCommentsLoaded,
  onInitialLoaded,
  onFilesDelete,
}: CommentProps) => {
  const [state, setState] = useState({
    data: [] as CommentResponse[],
    loading: false,
    current: 1,
    hasMore: true,
    adding: false,
    replyTo: null as CommentResponse | null,
    replyToUuid: null as string | null,
  });

  const [targetScroll, setTargetScroll] = useState<{
    type: ScrollTypes;
    commentId?: number;
    lastScrollPosition?: number;
  }>({
    type: 'initialScroll',
  });

  const { current, data } = state;

  const scrollRef = useRef<SimpleBar>(null);
  const inputRef = useRef<RichTextEditorHandle>(null);
  const [searchParams] = useSearchParams();

  const targetCommentId = searchParams.get('target_comment_id');

  const setComment = (text: string) => {
    const quill = inputRef.current?.getQuill();

    if (inputRef.current && quill?.editor) {
      quill.setEditorContents(quill.editor, text);
    }
  };

  const loadMore = () => {
    setState((prevState) => ({ ...prevState, current: prevState.current + 1 }));
  };

  const setReplyTo = (
    replyToUuid: string | null,
    value: CommentResponse | null,
    creator?: UserResponse
  ) => {
    setState((prevState) => ({ ...prevState, replyTo: value, replyToUuid }));

    const quill = inputRef.current?.getQuill();
    const editor = quill?.editor;

    if (!quill) {
      return;
    }

    if (creator && editor) {
      editor.setContents([
        {
          insert: {
            mention: {
              id: creator.uuid,
              value: creator.name,
              denotationChar: '@',
            },
          },
        },
        {
          insert: ' ',
        },
      ] as any);

      quill.value = editor.root.innerHTML;

      quill.setEditorSelection(editor, { index: 2, length: 0 });
    }
  };

  const scrollToBottom = () => {
    if (scrollRef.current?.getScrollElement()) {
      scrollRef.current
        .getScrollElement()
        .scrollTo(0, scrollRef.current.getScrollElement().scrollHeight);
    }
  };

  const scrollTo = (offsetTop: any) => {
    if (scrollRef.current?.getScrollElement()) {
      scrollRef.current.getScrollElement().scrollTo(0, offsetTop);
    }
  };

  const scrollToLastPosition = () => {
    const scrollArea = scrollRef.current?.getScrollElement();

    if (scrollArea) {
      scrollArea.scrollTop +=
        scrollArea.scrollHeight - (targetScroll.lastScrollPosition ?? 0);
    }
  };

  const fetchComments = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));
      const prevData = state.data;

      const res = await apiRequests.get(url, getParams(current));

      const data = res.data.data.reverse();

      setState((prevState) => ({
        ...prevState,
        loading: false,
        data: [...data, ...prevData],
        hasMore: res.data.meta.last_page > current,
      }));

      if (targetScroll.type !== 'initialScroll') {
        setTargetScroll({
          type: 'lastScrollPosition',
          lastScrollPosition:
            scrollRef.current?.getScrollElement()?.scrollHeight ?? 0,
        });
      }
    } catch (error) {
      asyncErrorHandler(error);
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleAddComments = async (values: any = {}, onFinish: any) => {
    try {
      setState((prevState) => ({ ...prevState, adding: true }));
      const res = await apiRequests.post(`${url}`, {
        ...values,
      });

      setState((prevState) => {
        const arr = [...prevState.data, res.data?.data];
        return {
          ...prevState,
          adding: false,
          data:
            arr.length > PAGE_SIZE ? arr.slice(arr.length - PAGE_SIZE) : arr,
          current: 1,
          hasMore: arr.length >= PAGE_SIZE,
        };
      });

      setTargetScroll({ type: 'newComment' });

      onFinish();
    } catch (error) {
      asyncErrorHandler(error);
      setState((prevState) => ({ ...prevState, adding: false }));
    }
  };

  const handleAddReply = async (
    commentId: string,
    values: any,
    onFinish: any
  ) => {
    try {
      setState((prevState) => ({ ...prevState, adding: true }));
      const res = await apiRequests.post(
        `${apiRoutes.BASE_URL}/projects/comments/${commentId}/comments`,
        {
          ...values,
        }
      );

      setState((prevState) => {
        const newData = prevState.data?.map((el: any) =>
          el?.uuid === commentId
            ? {
                ...el,
                replies: [...el?.replies, res?.data?.data],
              }
            : el
        );
        return {
          ...prevState,
          adding: false,
          data: newData,
        };
      });

      setTargetScroll({ type: 'newReply', commentId: res.data.data.uuid });

      onFinish();
    } catch (error) {
      asyncErrorHandler(error);
      setState((prevState) => ({ ...prevState, adding: false }));
    }
  };

  const handleUpdateComment = async (
    _uuid: any,
    value: any,
    users: any,
    onFinish: any
  ) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const res = await apiRequests.put(`${apiRoutes.COMMENTS}/${_uuid}`, {
        text: value,
        related_users: users ?? undefined,
      });
      setState((prevState) => ({
        ...prevState,
        loading: false,
        data: prevState.data?.map((el: any) =>
          el.uuid === _uuid ? res?.data?.data : el
        ),
      }));
      onFinish();
    } catch (error) {
      asyncErrorHandler(error);
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleUpdateReply = async (
    _uuid: any,
    commentId: any,
    value: any,
    users: any,
    onFinish: any
  ) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const res = await apiRequests.put(`${apiRoutes.COMMENTS}/${_uuid}`, {
        text: value,
        related_users: users ?? undefined,
      });
      setState((prevState) => {
        const newData = prevState.data?.map((el: any) =>
          el?.uuid === commentId
            ? {
                ...el,
                replies: el?.replies?.map((reply: any) =>
                  reply?.uuid === _uuid ? res?.data?.data : reply
                ),
              }
            : el
        );

        return {
          ...prevState,
          loading: false,
          data: newData,
        };
      });
      onFinish();
    } catch (error) {
      asyncErrorHandler(error);
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleDeleteUpload = async (_uuid: string, commentId: string) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      await apiRequests.delete(`${apiRoutes.UPLOADS}/${_uuid}`);
      setState((prevState) => ({
        ...prevState,
        data: prevState.data?.map((el: any) =>
          el.uuid === commentId
            ? {
                ...el,
                uploads: el?.uploads?.filter(
                  (upload: any) => upload.uuid !== _uuid
                ),
              }
            : el
        ),
        loading: false,
      }));

      onFilesDelete?.([_uuid]);
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleDeleteComment = async (_uuid: any) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      await apiRequests.delete(`${apiRoutes.COMMENTS}/${_uuid}`);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        data: prevState.data?.map((el: any) =>
          el.uuid === _uuid ? { ...el, deleted_at: dayjs().format() } : el
        ),
      }));
    } catch (error) {
      asyncErrorHandler(error);
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleDeleteReply = async (_uuid: any, commentId: any) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      await apiRequests.delete(`${apiRoutes.COMMENTS}/${_uuid}`);
      setState((prevState) => {
        const newData = prevState.data?.map((el: any) =>
          el?.uuid === commentId
            ? {
                ...el,
                replies: el?.replies?.map((reply: any) =>
                  reply?.uuid === _uuid
                    ? { ...reply, deleted_at: dayjs().format() }
                    : reply
                ),
              }
            : el
        );

        return {
          ...prevState,
          loading: false,
          data: newData,
        };
      });
    } catch (error) {
      asyncErrorHandler(error);
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleAddReaction = (commentId: string, reaction: any) => {
    setState((prevState: any) => ({
      ...prevState,
      data: prevState?.data?.map((el: any) =>
        el?.uuid === commentId
          ? { ...el, reactions: [...el?.reactions, reaction] }
          : el
      ),
    }));
  };

  const handleRemoveReaction = (commentId: string, reaction: any) => {
    setState((prevState: any) => ({
      ...prevState,
      data: prevState?.data?.map((el: any) =>
        el?.uuid === commentId
          ? {
              ...el,
              reactions: el?.reactions?.filter(
                (item: any) => item?.uuid !== reaction?.uuid
              ),
            }
          : el
      ),
    }));
  };

  const handleAddReplyReaction = (
    commentId: string,
    replyId: string,
    reaction: any
  ) => {
    setState((prevState) => {
      const data = prevState.data?.find((item) => item.uuid === commentId);
      const reply = data?.replies.find((item) => item.uuid === replyId);

      if (!data || !reply) {
        return prevState;
      }

      reply.reactions = [...reply.reactions, reaction];

      return { ...prevState };
    });
  };

  const handleRemoveReplyReaction = (
    commentId: string,
    replyId: string,
    reaction: any
  ) => {
    setState((prevState) => {
      const data = prevState.data?.find((item) => item.uuid === commentId);
      const reply = data?.replies.find((item) => item.uuid === replyId);

      if (!data || !reply) {
        return prevState;
      }

      reply.reactions = reply.reactions.filter(
        (item: any) => item.uuid !== reaction.uuid
      );

      return { ...prevState };
    });
  };

  useEffect(() => {
    if (!data.length) {
      return;
    }

    if (targetScroll.type === 'initialScroll') {
      if (targetCommentId) {
        onCommentsLoaded();

        const scrollEl = scrollRef.current?.getScrollElement();

        if (scrollEl) {
          const targetCommentEl = scrollEl.querySelector(
            `.comment-${targetCommentId}`
          ) as HTMLElement | null;

          if (targetCommentEl) {
            const parentId = targetCommentEl.dataset.parentUuid;

            let position =
              targetCommentEl.offsetTop +
              targetCommentEl.offsetHeight / 2 -
              scrollEl.offsetHeight / 2;

            if (parentId) {
              const parentEl = targetCommentEl.parentElement
                ?.parentElement as HTMLElement;

              position =
                parentEl.offsetTop +
                targetCommentEl.offsetTop +
                targetCommentEl.offsetHeight -
                scrollEl.offsetHeight;
            }

            scrollTo(position);
          }
        }
      } else {
        scrollToBottom();
      }

      setTargetScroll({ type: null });
      return;
    }

    if (targetScroll.type === 'newComment') {
      scrollToBottom();
      setTargetScroll({ type: null });
      return;
    }

    if (targetScroll.type === 'newReply') {
      const scrollEl = scrollRef.current?.getScrollElement();

      const commentEl = scrollEl?.querySelector(
        `.comment-${targetScroll.commentId}`
      ) as HTMLElement | null;

      const parentEl = commentEl?.parentElement?.parentElement;

      if (scrollEl && commentEl && parentEl) {
        scrollTo(
          parentEl.offsetTop +
            commentEl.offsetTop +
            commentEl.offsetHeight -
            scrollEl.offsetHeight
        );
      }

      setTargetScroll({ type: null });
      return;
    }

    if (targetScroll.type === 'lastScrollPosition') {
      scrollToLastPosition();
      setTargetScroll({ type: null });
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (current === 1 && data.length > 0) {
      return;
    }

    fetchComments().finally(() => {
      if (onInitialLoaded) {
        onInitialLoaded();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  return {
    state,
    inputRef,
    scrollRef,
    targetCommentId,
    setComment,
    handleUpdateComment,
    handleDeleteComment,
    handleAddComments,
    loadMore,
    handleDeleteUpload,
    handleUpdateReply,
    handleDeleteReply,
    handleAddReply,
    setReplyTo,
    handleAddReaction,
    handleRemoveReaction,
    handleRemoveReplyReaction,
    handleAddReplyReaction,
  };
};

export default useComments;
