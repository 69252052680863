import React, { useEffect } from 'react';
import Navbar from '../Navbar';
import Toast from '../Toast';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TasksStateType } from 'src/store/tasks/reducer';
import { getPendingForms } from 'src/store/pendingForms/reducer';
import { getPendingTasks } from 'src/store/pendingTasks/reducer';
import { getProjects } from 'src/store/projects/reducer';
import FloatActions from './FloatActions';
import ENVIRONMENT from '../../utils/environments';

export default function Layout({
  fullPage = false,
  children,
}: {
  fullPage?: boolean;
  children?: React.ReactNode;
}) {
  const location = useLocation();

  const pendingTasks: TasksStateType = useSelector(
    (globalState: any) => globalState.pendingTasks
  );
  const pendingForms = useSelector(
    (globalState: any) => globalState.pendingForms
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      // @ts-ignore
      getPendingForms({
        'filters[forms_answers.status][]': 'pending',
      })
    );
    dispatch(
      // @ts-ignore
      getPendingTasks({
        'filters[task_status]': ['waiting_approval', 'pending'],
      })
    );
    dispatch(
      // @ts-ignore
      getProjects({
        'filters[projects.status]': [
          'paused',
          'waiting_to_launch',
          'in_progress',
          'active',
        ],
      })
    );
  }, [dispatch]);

  const hidePendingMenu =
    !pendingTasks.data?.data?.length && !pendingForms.data?.data?.length;

  return (
    <div className={`flex h-full flex-col ${fullPage ? 'bg-white' : ''}`}>
      <Navbar hidePendingMenu={hidePendingMenu} />

      {fullPage ? (
        children
      ) : (
        <div
          className={`mx-auto mt-16 w-full max-w-[1280px] px-7 py-4 md:mt-14 md:p-10 ${
            ENVIRONMENT.IS_PRODUCTION ? '' : 'mt-24 md:mt-20'
          }`}
        >
          {children}
        </div>
      )}

      <FloatActions />

      {!location.pathname.includes('pending') &&
        (!!pendingTasks.data?.data?.length ||
          !!pendingForms.data?.data?.length) && <Toast />}
    </div>
  );
}
