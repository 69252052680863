import { createSlice } from '@reduxjs/toolkit';
import { ApiResponse, CommentResponse } from 'src/types';

export interface InboxStateType {
  loading: boolean;
  data: ApiResponse<CommentResponse> | null;
  error: null | string;
}

const initialState: InboxStateType = {
  loading: false,
  data: null,
  error: null,
};

export const inboxSlice = createSlice({
  name: 'inbox',
  initialState,
  reducers: {
    getInbox: () => {},
    setInboxLoading: (state) => ({ ...state, loading: true }),
    setInboxData: (state, action) => ({
      ...state,
      loading: false,
      data: action.payload,
    }),
    clearInboxData: () => initialState,
    setInboxError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
  },
});

export const {
  setInboxData,
  setInboxError,
  setInboxLoading,
  getInbox,
  clearInboxData,
} = inboxSlice.actions;

export default inboxSlice.reducer;
