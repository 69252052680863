/* eslint-disable @typescript-eslint/dot-notation */
import { Col, Dropdown, Empty, Input, Row, Space, Typography } from 'antd';
import SimpleBar from 'simplebar-react';

import { FC, useEffect, useState } from 'react';

import {
  CloseCircleOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  convertSnakeCaseToTitleCase,
  includesInObject,
} from 'src/utils/stringHelpers';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import StatusTag from '../StatusTag';

interface GlobalSearchProps {
  dropdownRenderClassName?: string;
  onLinkClick?: any;
}

const { Text, Title } = Typography;
const GlobalSearch: FC<GlobalSearchProps> = ({
  dropdownRenderClassName,
  onLinkClick,
}) => {
  const [state, setState] = useState({
    loading: false,
    data: null as any,
    search: null as any,
    visible: false,
  });

  const { loading, data, search, visible } = state;
  const filteredData: any = {};
  Object.keys(data ?? {})?.forEach((el: any) => {
    const newData = data?.[el]?.filter((item: any) => {
      return includesInObject(search, item);
    });
    if (el === 'forms' || el === 'projects' || el === 'tasks') {
      filteredData[el] = newData;
    }
  });

  const hasData = Object.keys(data ?? {})?.find(
    (el: any) => data?.[el]?.length !== 0
  );

  const navigate = useNavigate();

  const toggleVisible = () => {
    setState((prevState) => ({ ...prevState, visible: !prevState.visible }));
  };

  useEffect(() => {
    const ourRequest = axios.CancelToken.source();

    const fetchData = async () => {
      try {
        setState((prevState: any) => ({ ...prevState, loading: true }));

        const params: any = {};
        params['search_term'] = search;

        const res = await apiRequests.get(`${apiRoutes.GLOBAL_SEARCH}`, params);

        setState((prevState: any) => ({
          ...prevState,
          loading: false,
          data: res?.data?.data,
        }));
      } catch (error) {
        if (!axios.isCancel(error)) {
          asyncErrorHandler(error);
          setState((prevState: any) => ({ ...prevState, loading: false }));
        }
      }
    };

    fetchData();

    return () => {
      ourRequest.cancel();
    };
  }, [search]);

  return (
    <Dropdown
      open={visible}
      dropdownRender={() => (
        <div
          className={`rounded-[3px] bg-white shadow-xl ${dropdownRenderClassName}`}
        >
          {!hasData ? (
            <div style={{ padding: 30 }}>
              <Empty />
            </div>
          ) : (
            <>
              {/* @ts-ignore */}
              <SimpleBar style={{ maxHeight: 400 }}>
                {Object.keys(filteredData ?? {}).map((type: string) => {
                  const parsedData = filteredData?.[type];

                  if (parsedData?.length === 0) {
                    return null;
                  }

                  return (
                    <div className="global-search-item" key={type}>
                      <Title level={5} className="mb-m">
                        {convertSnakeCaseToTitleCase(type)}
                      </Title>
                      {parsedData?.map((item: any, key: any) => {
                        return (
                          <div
                            onClick={() => {
                              if (type === 'projects') {
                                navigate(`/projects/${item?.uuid}`);
                              } else if (type === 'tasks') {
                                navigate(
                                  `/dashboard?task_id=${item?.task_uuid}`
                                );
                              } else if (type === 'forms') {
                                navigate(`/pending?form_id=${item?.uuid}`);
                              }

                              if (onLinkClick) {
                                onLinkClick();
                              }
                            }}
                            className="global-search-data"
                            key={key}
                          >
                            <Row
                              wrap={false}
                              justify="space-between"
                              gutter={10}
                            >
                              <Col
                                span={
                                  item?.status || item?.task_status ? 18 : 24
                                }
                              >
                                <Text ellipsis>
                                  {item?.business_name ??
                                    item?.title ??
                                    item?.name ??
                                    item?.task_title}
                                </Text>
                              </Col>
                              {(item?.status || item?.task_status) && (
                                <Col>
                                  <StatusTag
                                    value={item?.status ?? item?.task_status}
                                  />
                                </Col>
                              )}
                            </Row>
                            {(type === 'projects' || type === 'tasks') && (
                              <Row>
                                <Col span={24}>
                                  <Text
                                    style={{ fontSize: 12 }}
                                    className="primary"
                                    ellipsis
                                  >
                                    {item?.customer_business_name}
                                  </Text>
                                </Col>
                              </Row>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </SimpleBar>
            </>
          )}
        </div>
      )}
    >
      <div>
        <Input
          placeholder="Search anything"
          value={search}
          onFocus={toggleVisible}
          onBlur={() => {
            setTimeout(() => toggleVisible(), 300);
          }}
          onChange={(e) => {
            setState((prevState) => ({ ...prevState, search: e.target.value }));
          }}
          size="large"
          className="!w-full !rounded-[8px]"
          suffix={
            <Space>
              <Loading3QuartersOutlined
                style={{ visibility: loading ? 'visible' : 'hidden' }}
                spin
                size={20}
              />
              {search && (
                <CloseCircleOutlined
                  className="pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setState((prevState) => ({
                      ...prevState,
                      search: undefined,
                    }));
                  }}
                />
              )}
            </Space>
          }
        />
      </div>
    </Dropdown>
  );
};

export default GlobalSearch;
