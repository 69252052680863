/* eslint-disable react-hooks/exhaustive-deps */
import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';

const useProjectAssets = () => {
  const params: any = useParams();
  const [data, setData] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await apiRequests.get(
          `${apiRoutes.PROJECTS}/${params?.uuid}/files`
        );

        setData(res.data.data);
      } catch (error) {
        // asyncErrorHandler(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const downloadAllAssets = async (data: any) => {
    try {
      const res = await apiRequests.post(`${apiRoutes.MASS_DOWNLOAD}`, data);
      message.success(res?.data?.message);
    } catch (error) {
      asyncErrorHandler(error);
    }
  };

  return {
    data,
    loading,
    downloadAllAssets,
  };
};

export default useProjectAssets;
