import ENVIRONMENT from './environments';

const { BASE_URL } = ENVIRONMENT;

const apiRoutes = {
  BASE_URL,

  LOGIN: `${BASE_URL}/auth/login`,
  USER_INFO: `${BASE_URL}/auth/user`,
  RESET_EMAIL: `${BASE_URL}/auth/password/email`,
  RESET_PASSWORD: `${BASE_URL}/auth/reset-password`,
  CONTACT_REGISTER: `${BASE_URL}/auth/contact-register`,

  CUSTOMER_INVITE: `${BASE_URL}/billing/customer-invitations`,

  USERS: `${BASE_URL}/auth/users`,
  CONTACTS: `${BASE_URL}/billing/contacts`,
  CUSTOMERS: `${BASE_URL}/billing/customers`,

  PROJECT_TASKS: `${BASE_URL}/projects/tasks`,
  PROJECTS: `${BASE_URL}/projects/projects`,
  PROJECT_REQUEST: `${BASE_URL}/projects/requests`,
  INBOX: `${BASE_URL}/mentions/client-inbox`,
  PROJECT_LOG: `${BASE_URL}/comments-report?filters[commentable_type][]=projects&sort[by]=created_at&sort[direction]=desc`,
  RESOURCES: `${BASE_URL}/cms/resources`,

  // COMMENTS
  COMMENTS: `${BASE_URL}/comments`,
  REACTION: `${BASE_URL}/reaction`,

  // UPLOAD
  CREATE_PRE_SIGNED: `${BASE_URL}/create-pre-signed-url`,
  UPLOADS: `${BASE_URL}/uploads`,

  MASS_DOWNLOAD: `${BASE_URL}/mass-download`,
  ANSWERS: `${BASE_URL}/forms/answers`,
  GLOBAL_SEARCH: `${BASE_URL}/global-search`,

  // ANALYTICS
  ANALYTICS_GOOGLE: `${BASE_URL}/google-analytics`,
  ANALYTICS_FB: `${BASE_URL}/facebook-analytics`,

  // NOTIFICATIONS
  NOTIFICATIONS: `${BASE_URL}/notifications`,
  FCM_TOKEN: `${BASE_URL}/fcm-token`,
};

export default apiRoutes;
