import React, { FC } from 'react';

import { Avatar } from 'antd';
import { AvatarSize } from 'antd/lib/avatar/SizeContext';
import { UserResponse } from 'src/types';
import { getAvatar } from 'src/utils/stringHelpers';
import { generateHexColor } from 'src/utils/randomColorGenerator';

interface UserAvatarProps {
  user?: UserResponse | undefined | null;
  size?: AvatarSize | undefined;
}

const UserAvatar: FC<UserAvatarProps> = ({ user, ...props }) => {
  const avatarObj = getAvatar(user);

  return (
    <Avatar
      {...props}
      src={avatarObj?.value}
      style={{ background: generateHexColor(avatarObj?.value) }}
    >
      {avatarObj?.type === 'text' ? avatarObj?.value : undefined}
    </Avatar>
  );
};

export default UserAvatar;
