import { AxiosResponse } from 'axios';
import { call, put, StrictEffect, takeLatest } from 'redux-saga/effects';

import {
  getPendingForms,
  setPendingFormsData,
  setPendingFormsError,
  setPendingFormsLoading,
} from './reducer';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';

const { get } = apiRequests;

function* getPendingFormsSagaListener(action: any) {
  try {
    yield put(setPendingFormsLoading());
    const res: AxiosResponse = yield call(
      get,
      apiRoutes.ANSWERS,
      action.payload
    );

    yield put(setPendingFormsData(res.data));
  } catch (error) {
    yield put(setPendingFormsError(error));
  }
}
function* pendingFormsSaga(): Generator<StrictEffect> {
  yield takeLatest(getPendingForms.type, getPendingFormsSagaListener);
}

export default pendingFormsSaga;
