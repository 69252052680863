import { Navigate, useParams, useSearchParams } from 'react-router-dom';

const RedirectProjectPage = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const taskId = searchParams.get('uuid');

  let url = `/projects/${params.uuid}`;

  url += taskId ? `?task_id=${taskId}` : '';

  return <Navigate to={url} replace />;
};

export default RedirectProjectPage;
