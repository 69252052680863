import { AxiosResponse } from 'axios';
import { call, put, StrictEffect, takeLatest } from 'redux-saga/effects';

import {
  getProjects,
  setProjectsData,
  setProjectsError,
  setProjectsLoading,
} from './reducer';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';

const { get } = apiRequests;

function* getProjectsSagaListener(action: any) {
  try {
    yield put(setProjectsLoading());
    const res: AxiosResponse = yield call(
      get,
      apiRoutes.PROJECTS,
      action.payload
    );

    yield put(setProjectsData(res.data));
  } catch (error) {
    yield put(setProjectsError(error));
  }
}
function* projectsSaga(): Generator<StrictEffect> {
  yield takeLatest(getProjects.type, getProjectsSagaListener);
}

export default projectsSaga;
