import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  data: null,
  error: null,
};

export const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    getCampaigns: () => {},
    setCampaignsLoading: (state) => ({ ...state, loading: true }),
    setCampaignsData: (state, action) => ({
      ...state,
      loading: false,
      data: action.payload,
    }),
    clearCampaignsData: () => initialState,
    setCampaignsError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
  },
});

export const {
  setCampaignsData,
  setCampaignsError,
  setCampaignsLoading,
  getCampaigns,
  clearCampaignsData,
} = campaignsSlice.actions;

export default campaignsSlice.reducer;
