import { List, Skeleton } from 'antd';
import React, { useMemo, useState } from 'react';
import { TbBox, TbPencil } from 'react-icons/tb';
import { IoFolderOpenOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import Box from '../../components/Box';
import Forms from '../../components/Forms';
import Advertising from '../../components/Advertising';
import Task from '../../components/Task';
import useProject from './useProject';
import { parseDateToUSFormat } from 'src/utils/stringHelpers';
import StatusTag from 'src/components/StatusTag';
import Activity from 'src/components/ProjectComments';
import ProjectContext from 'src/context/project';
import ProjectAssets from 'src/components/ProjectAssets';
import { TasksStateType, getProjectTasks } from 'src/store/tasks/reducer';
import { useSelector } from 'react-redux';

import 'src/components/RichTextEditor/index.style.scss';
import RichTextEditor from '../../components/RichTextEditor';
import SimpleBar from 'simplebar-react';
import useProjectAssets from 'src/components/ProjectAssets/useProjectAssets';
import AxiosErrorResult from '../../components/AxiosErrorResult';

const Project: React.FC = () => {
  const [loadedComments, setLoadedComments] = useState(false);
  const { state, onUpdateTrigger } = useProject();
  const {
    data: projectAssets,
    loading: loadingAssets,
    downloadAllAssets,
  } = useProjectAssets();

  const { loading: loadingProject, data, axiosError, updateTrigger } = state;

  const params: any = useParams();

  const tasks: TasksStateType = useSelector(
    (globalState: any) => globalState.tasks
  );

  const pendingForms = useMemo(
    () => data?.answers?.filter((item) => item.status === 'pending'),
    [data?.answers]
  );

  const dataItems = useMemo(() => {
    return data?.items?.filter((item) => item.type !== 'time_budget');
  }, [data?.items]);

  const loading =
    loadingProject || loadingAssets || tasks.loading || !loadedComments;

  if (axiosError) {
    return <AxiosErrorResult error={axiosError} />;
  }

  return (
    <ProjectContext.Provider
      value={{
        updateTrigger,
        onUpdateTrigger,
      }}
    >
      <div className="mb-[20px] flex w-full flex-col lg:flex-row lg:space-x-8">
        <div className="space-y-4 pl-1 lg:w-4/12">
          <h1 className="text-3xl font-light text-blue-700">{data?.title}</h1>

          <div className="flex items-center space-x-2 pb-4">
            <IoFolderOpenOutline
              className="block h-6 w-6 text-blue-600"
              aria-hidden="true"
            />

            <p className="text-lg font-bold text-blue-600">Project details</p>

            <StatusTag value={data?.status} />
          </div>

          <div className="w-full space-y-4">
            <p className="text-[14px] font-light text-gray-500">
              Project manager:{' '}
              <span className="font-bold">
                {data?.project_manager?.name ?? '-'}
              </span>
            </p>

            <p className="text-[14px] font-light text-gray-500">
              Account manager:{' '}
              <span className="font-bold">
                {data?.account_manager?.name ?? '-'}
              </span>
            </p>

            <p className="text-[14px] font-light text-gray-500">
              Start date:{' '}
              <span className="font-bold">
                {' '}
                {parseDateToUSFormat(data?.start_date)}
              </span>
            </p>
          </div>
        </div>

        {data?.advertising && (
          <div className="mt-8 lg:mt-0 lg:w-8/12">
            <Advertising data={data} />
          </div>
        )}
      </div>

      {loading && <Skeleton className="pt-2xl" />}

      <div className="mb-10 grid grid-cols-1 gap-8 space-y-0 lg:grid-cols-2">
        <Task
          data={tasks.data?.data}
          hidden={loading || !tasks.data?.data?.length}
          loading={false}
          onDispatch={getProjectTasks}
          page="project"
          params={{
            'filters[project.uuid][]': params?.uuid,
          }}
          includeCompleted
        />

        {!loading && data?.items?.length ? (
          <div className="flex w-full flex-col">
            <div className="flex items-center space-x-2 pb-4">
              <TbBox
                className="block h-6 w-6 text-blue-600"
                aria-hidden="true"
              />
              <p className="text-[16px] font-bold text-blue-600">
                My subscription
              </p>
            </div>

            <div className="h-[300px]">
              <Box>
                <List
                  itemLayout="vertical"
                  dataSource={dataItems}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta title={item.name} />
                      <p
                        className="pb-4 pl-2"
                        dangerouslySetInnerHTML={{
                          __html: item.description?.replaceAll('\n', '<br />'),
                        }}
                      />
                    </List.Item>
                  )}
                />
              </Box>
            </div>
          </div>
        ) : undefined}

        {!loading && pendingForms?.length ? (
          <Forms data={pendingForms} />
        ) : undefined}

        {!loading &&
        (projectAssets?.project_files?.length ||
          projectAssets?.tasks_files?.length) ? (
          <ProjectAssets
            data={projectAssets}
            downloadAllAssets={downloadAllAssets}
          />
        ) : undefined}

        {!loading && data?.sales_notes ? (
          <div className="flex w-full flex-col">
            <div className="flex items-center space-x-2 pb-4">
              <TbPencil
                className="block h-6 w-6 text-blue-600"
                aria-hidden="true"
              />
              <p className="text-[16px] font-bold text-blue-600">Notes</p>
            </div>

            <Box noPadding>
              <SimpleBar forceVisible="y" className="h-[300px] w-full">
                <RichTextEditor
                  defaultValue={data.sales_notes}
                  readonly
                  noContainer
                  className="p-4"
                />
              </SimpleBar>
            </Box>
          </div>
        ) : undefined}

        <Activity
          onInitialLoaded={() => setLoadedComments(true)}
          className={loading ? 'hidden' : ''}
        />
      </div>
    </ProjectContext.Provider>
  );
};

export default Project;
