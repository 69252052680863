import { FC } from 'react';
import dayjs from 'dayjs';
import { Dropdown, MenuItemProps, MenuProps, Tooltip, Typography } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { checkExternalUrl, getCompletePathUrl } from 'src/utils/stringHelpers';

export type Notification = {
  id: string;
  number: number;
  data: {
    title: string;
    sub_title: string | null | undefined;
    content: string;
    action_url: string;
  };
  notifiable_id: number | string;
  notifiable_type: string;
  read_at: string | null;
  created_at: string;
  updated_at: string;
};

interface CardDropdownNotificationProps {
  notification: Notification;
  onMarkAsRead: (item: Notification) => void;
  onMarkAsUnread: (item: Notification) => void;
  onDelete: (item: Notification) => void;
  onClick: () => void;
}

const { Text } = Typography;

const DropdownCard: FC<CardDropdownNotificationProps> = ({
  notification,
  onMarkAsRead,
  onMarkAsUnread,
  onDelete,
  onClick,
}) => {
  const { data, read_at: readAt, created_at: createdAt } = notification;
  const { title, sub_title: subTitle, content, action_url: actionUrl } = data;

  const markAsReadHandler: MenuItemProps['onClick'] = (info) => {
    onMarkAsRead(notification);
  };

  const markAsUnreadHandler: MenuItemProps['onClick'] = (info) => {
    onMarkAsUnread(notification);
  };

  const deleteHandler: MenuItemProps['onClick'] = (info) => {
    onDelete(notification);
  };

  const cardClickHandler = () => {
    onClick();

    if (!readAt) {
      onMarkAsRead(notification);
    }
  };

  const LinkContent = (
    <>
      <div>
        <p className="bl-dropdown-card--title">{title}</p>
        {subTitle ? <Text type="secondary">{subTitle}</Text> : undefined}
        <article>{content}</article>
      </div>

      <Tooltip title={dayjs(createdAt).format('MMMM DD, YYYY HH:mm A')}>
        {dayjs(createdAt).fromNow(true)}
      </Tooltip>
    </>
  );

  const menuItems: MenuProps['items'] = [];

  if (!readAt) {
    menuItems.push({
      key: '0',
      label: 'Mark as read',
      onClick: markAsReadHandler,
    });
  } else {
    menuItems.push({
      key: '1',
      label: 'Mark as unread',
      onClick: markAsUnreadHandler,
    });
  }

  menuItems.push({ key: '2', label: 'Delete', onClick: deleteHandler });

  return (
    <div
      className={`bl-dropdown-card ${
        !readAt ? 'bl-dropdown-card--unread' : ''
      }`}
    >
      {checkExternalUrl(actionUrl) ? (
        <a
          href={actionUrl}
          target="_blank"
          rel="noreferrer"
          style={{ color: 'rgb(58, 66, 77)' }}
          onClick={cardClickHandler}
        >
          {LinkContent}
        </a>
      ) : (
        <Link
          to={getCompletePathUrl(actionUrl)}
          style={{ color: 'rgb(58, 66, 77)' }}
          onClick={cardClickHandler}
        >
          {LinkContent}
        </Link>
      )}

      <Dropdown
        placement="bottomRight"
        trigger={['click', 'hover']}
        menu={{ items: menuItems }}
      >
        <button type="button" className="default-button">
          <MoreOutlined style={{ fontSize: 24 }} />
        </button>
      </Dropdown>
    </div>
  );
};

export default DropdownCard;
