import { FC } from 'react';
import { TbForms, TbUserCircle } from 'react-icons/tb';
import { List, Skeleton } from 'antd';
import Box from '../../components/Box';
import { AnswerResponse } from 'src/types';
import StatusTag from '../StatusTag';
import { useLocation, useNavigate } from 'react-router-dom';
import FormDrawer from '../FormDrawer';
import { IoFolderOpen } from 'react-icons/io5';
import { IoIosArrowForward } from 'react-icons/io';

interface FormsProps {
  className?: string;
  data?: AnswerResponse[];
  loading?: boolean;
}

const Forms: FC<FormsProps> = ({ className, data, loading }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <FormDrawer />
      <div className={`flex w-full flex-col ${className}`}>
        <div className="flex items-center space-x-2 pb-4">
          <TbForms className="block h-6 w-6 text-blue-600" aria-hidden="true" />
          <p className="text-[16px] font-bold text-blue-600">Forms</p>
        </div>
        <div className="h-[300px]">
          <Box>
            {loading ? (
              <Skeleton className="mt-[20px]" />
            ) : (
              <List
                dataSource={data}
                renderItem={(item: any) => (
                  <List.Item
                    className="cursor-pointer"
                    onClick={() =>
                      navigate(`${location.pathname}?form_id=${item?.uuid}`, {
                        preventScrollReset: true,
                      })
                    }
                    key={item?.uuid}
                  >
                    <div className="flex w-full items-center justify-between">
                      <div>
                        <div>{item?.title}</div>
                        <div className="flex flex-wrap">
                          <div className="flex items-center gap-[4px] text-blue-500">
                            <TbUserCircle className="text-[18px]" />
                            <span>
                              {item?.project?.customer?.business_name}
                            </span>
                          </div>
                          &nbsp;/&nbsp;{' '}
                          <div className="flex items-center gap-[4px] text-blue-500">
                            <IoFolderOpen />
                            <span>{item?.project?.title}</span>
                          </div>
                        </div>
                      </div>

                      <div className="whitespace-nowrap">
                        <StatusTag value={item?.status} />
                        <IoIosArrowForward className="ml-1 inline-block text-lg text-gray-600" />
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            )}
          </Box>
        </div>
      </div>
    </>
  );
};

export default Forms;
