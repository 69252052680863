/* eslint-disable react-hooks/exhaustive-deps */
import { TbMessages } from 'react-icons/tb';
import React, { useEffect } from 'react';
import Box from '../Box';
import { Empty, List, Skeleton } from 'antd';

import UserAvatar from '../UserAvatar';
import {
  mentionCommentParser,
  parseDateToUSFormat,
} from 'src/utils/stringHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { getActivities } from 'src/store/activities/reducer';
import { useNavigate } from 'react-router-dom';

import 'src/components/RichTextEditor/index.style.scss';
import RichTextEditor from '../RichTextEditor';
import { RxQuestionMarkCircled } from 'react-icons/rx';
import { useAppTour } from '../../context/appTourContext';
import { deletedMessage } from '../CommentMessage';

interface ActivityType {
  loading?: boolean;
}

const Activity: React.FC<ActivityType> = ({ loading: loading1 }) => {
  const { data, loading } = useSelector(
    (globalState: any) => globalState.activities
  );

  const { setOpenTour } = useAppTour();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getActivities());
  }, []);

  return (
    <div>
      <div className="mb-2 flex gap-2 px-1 text-blue-600">
        <TbMessages className="text-2xl" />
        <span className="flex-auto font-bold">Recent activities</span>
        <button
          type="button"
          className="z-10"
          onClick={() => {
            setOpenTour(true, 10);
          }}
        >
          <RxQuestionMarkCircled className="text-2xl" />
        </button>
      </div>

      <div className="h-[685px] overflow-auto">
        <Box>
          {(loading && !data) || loading1 ? (
            <Skeleton className="mt-[20px]" />
          ) : (
            <>
              {!loading && (!data || data?.data?.length === 0) ? (
                <Empty className="mt-[20px]" />
              ) : (
                <List
                  dataSource={data?.data}
                  renderItem={(item: any, i) => (
                    <List.Item
                      onClick={() => {
                        if (item.location?.type === 'project') {
                          navigate(
                            `/projects/${item?.location?.uuid}?target_comment_id=${item?.uuid}`
                          );
                        } else if (item.location?.type === 'task') {
                          navigate(
                            `/?task_id=${item?.location?.uuid}&target_comment_id=${item?.uuid}`
                          );
                        }
                      }}
                      key={i}
                      className="cursor-pointer"
                    >
                      <div className="flex w-full space-x-4">
                        <div>
                          <UserAvatar user={item?.creator} />
                        </div>
                        <div className="w-max flex-1 space-y-2 rounded-[16px] bg-gray-100 p-4">
                          <p className="text-[12px] text-gray-400">
                            {parseDateToUSFormat(item?.created_at)} -{' '}
                            {item?.location?.description}
                          </p>
                          <RichTextEditor
                            className="comment-styles"
                            defaultValue={
                              !item.deleted_at
                                ? mentionCommentParser(item.text)
                                : deletedMessage
                            }
                            readonly
                            noContainer
                          />
                        </div>
                      </div>
                    </List.Item>
                  )}
                />
              )}
            </>
          )}
        </Box>
      </div>
    </div>
  );
};

export default Activity;
