import { Button, Form, Input, Modal, Space, Typography } from 'antd';
import { FC, useState } from 'react';
import { MdOutlinePhoneIphone } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import { UserResponse } from 'src/types';
import { updateAuthData } from 'src/store/auth/reducer';

const { Title } = Typography;

interface SmsSignUpModalProps {
  user: UserResponse;
  onClose?: () => void;
}

const SmsSignUpModal: FC<SmsSignUpModalProps> = ({ user, onClose }) => {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const submitHandler = async (data: any) => {
    setLoading(true);

    try {
      await apiRequests.put(`${apiRoutes.CONTACTS}/${user?.contact?.uuid}`, {
        ...data,
        sms_notifications: true,
      });

      dispatch(
        updateAuthData({
          ...user,
          contact: {
            ...user.contact,
            phone: data.phone,
          },
          sms_notifications: true,
        })
      );

      setOpen(false);
    } catch (error) {
      asyncErrorHandler(error);
      setLoading(false);
    }
  };

  const onCancelHandler = () => {
    if (user.sms_notifications === null) {
      apiRequests.put(`${apiRoutes.CONTACTS}/${user?.contact?.uuid}`, {
        sms_notifications: false,
      });

      dispatch(
        updateAuthData({
          ...user,
          sms_notifications: false,
        })
      );
    }

    setOpen(false);
  };

  return (
    <Modal
      open={open}
      zIndex={1070}
      footer={null}
      onCancel={onCancelHandler}
      afterClose={() => onClose?.()}
    >
      <Title level={3} className="pb-6 text-center">
        Get notification via SMS
      </Title>

      <div className="mx-auto w-64">
        <Form onFinish={submitHandler}>
          <Form.Item
            name="phone"
            rules={[
              { required: true, message: 'Please enter the phone number' },
            ]}
            initialValue={user.contact?.phone}
          >
            <Input
              size="large"
              placeholder="Phone number"
              prefix={<MdOutlinePhoneIphone />}
            />
          </Form.Item>

          <Space direction="vertical" className="w-full" size={12}>
            <Button htmlType="submit" type="primary" loading={loading} block>
              Sign up for SMS
            </Button>

            <Button type="primary" ghost block onClick={onCancelHandler}>
              No thanks
            </Button>
          </Space>
        </Form>
      </div>

      <div className="mt-10">
        By submitting this form and signing up for texts, you consent to receive
        text messages from Lionshare. Consent is not a condition of purchase.
        Msg & data rates may apply. Msg frequency varies. Unsubscribe at any
        time by replying STOP or disabling SMS notifications in the notification
        options in your profile on Lionshare.
      </div>
    </Modal>
  );
};

export default SmsSignUpModal;
