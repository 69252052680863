import { FC, useState } from 'react';
import { TbPaperclip } from 'react-icons/tb';
import Box from '../Box';
import { Button, Collapse, Empty } from 'antd';
import DownloadAsset from '../DownloadAsset';

interface ProjectAssetsProps {
  data: any;
  downloadAllAssets: (data: any) => Promise<void>;
}

const ProjectAssets: FC<ProjectAssetsProps> = ({ data, downloadAllAssets }) => {
  const [projectDownloading, setProjectDownloading] = useState(false);
  const [taskDownloading, setTaskDownloading] = useState(false);

  return (
    <div className="flex w-full flex-col">
      <div className="flex items-center space-x-2 pb-4">
        <TbPaperclip
          className="block h-6 w-6 text-blue-600"
          aria-hidden="true"
        />
        <p className="text-[16px] font-bold text-blue-600">Assets</p>
      </div>

      <div className="h-[300px]">
        <Box>
          <Collapse
            defaultActiveKey={['1']}
            accordion
            size="small"
            ghost
            expandIconPosition="end"
          >
            <Collapse.Panel header="Project" key="1">
              {data?.project_files?.length > 0 ? (
                <div className="w-full overflow-auto">
                  <Button
                    shape="round"
                    loading={projectDownloading}
                    onClick={() => {
                      setProjectDownloading(true);
                      downloadAllAssets({
                        full_download: true,
                        file_ids: data?.project_files?.map(
                          (item: any) => item?.uuid
                        ),
                      }).finally(() => setProjectDownloading(false));
                    }}
                    block
                    type="primary"
                    className="mb-4"
                  >
                    Download all
                  </Button>
                  {data?.project_files?.map((asset: any, i: any) => {
                    return (
                      <div
                        key={i}
                        className="flex items-center justify-between space-y-4"
                      >
                        <div>{asset?.name}</div>
                        <DownloadAsset uuid={asset?.uuid} name={asset?.name} />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <Empty description="Empty Assets" />
              )}
            </Collapse.Panel>
            <Collapse.Panel header="Task" key="2">
              {data?.tasks_files?.length > 0 ? (
                <div className="w-full overflow-auto">
                  <Button
                    shape="round"
                    loading={taskDownloading}
                    onClick={() => {
                      setTaskDownloading(true);
                      downloadAllAssets({
                        full_download: true,
                        task_ids: data?.tasks_files?.map(
                          (item: any) => item?.uuid
                        ),
                      }).finally(() => setTaskDownloading(false));
                    }}
                    block
                    type="primary"
                    className="mb-4"
                  >
                    Download all
                  </Button>
                  <Collapse
                    defaultActiveKey={[0]}
                    accordion
                    size="small"
                    ghost
                    expandIconPosition="end"
                  >
                    {data?.tasks_files?.map((task: any, i: any) => {
                      return (
                        <Collapse.Panel key={i} header={task?.title}>
                          {task?.uploads?.map((asset: any, j: any) => {
                            return (
                              <div
                                key={j}
                                className="flex items-center justify-between space-y-4 pl-[20px]"
                              >
                                <div>{asset?.name}</div>
                                <DownloadAsset
                                  uuid={asset?.uuid}
                                  name={asset?.name}
                                />
                              </div>
                            );
                          })}
                        </Collapse.Panel>
                      );
                    })}
                  </Collapse>
                </div>
              ) : (
                <Empty description="Empty Assets" />
              )}
            </Collapse.Panel>
          </Collapse>
        </Box>
      </div>
    </div>
  );
};

export default ProjectAssets;
