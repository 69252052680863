import {
  CheckOutlined,
  CloseOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { FC } from 'react';

interface StatusActionsProps {
  selected: string;
  onChange: any;
}

const StatusActions: FC<StatusActionsProps> = ({
  selected,
  onChange = () => null,
}) => {
  return (
    <Row gutter={[8, 8]} className="mb-6">
      <Col xs={24} md={8}>
        <Button
          className="btn-green btn-icon-left-md md:!text-left"
          onClick={() => onChange('approved')}
          icon={<CheckOutlined className="text-lg" />}
          disabled={selected === 'approved'}
          size="large"
          block
        >
          Approve
        </Button>
      </Col>
      <Col xs={24} md={8}>
        <Button
          className="btn-orange btn-icon-left-md md:!text-left"
          onClick={() => onChange('request_changes')}
          icon={<WarningOutlined className="text-lg" />}
          disabled={selected === 'request_changes'}
          size="large"
          block
        >
          Request changes
        </Button>
      </Col>
      <Col xs={24} md={8}>
        <Button
          className="btn-red btn-icon-left-md md:!text-left"
          onClick={() => onChange('rejected')}
          icon={<CloseOutlined className="text-lg" />}
          disabled={selected === 'rejected'}
          size="large"
          block
        >
          Cancel
        </Button>
      </Col>
    </Row>
  );
};

export default StatusActions;
