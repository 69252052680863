/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from 'react';

import { Form, Select, Typography } from 'antd';
import _ from 'lodash';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import apiRequests from 'src/utils/api';

const { Text } = Typography;
interface MultipleSelectSearchProps {
  api: string;
  labelKeys?: string[];
  valueKey?: string;
  name?: string;
  label?: string;
  rules?: any;
  className?: any;
  initialOptions?: any[];
  startingOptions?: any[];
  initialOption?: any;
  defaultFilter?: any;
  labelCol?: any;
  mode?: any;
  style?: any;
  formItemClassName?: string;
  placeholder?: string;
  render?: any;
  onChange?: any;
  defaultParams?: any;
  size?: string;
  disabled?: boolean;
  onLoad?: any;
  allowClear?: boolean;
  filterBeforeRender?: any;
  searchKey?: string;
  renderOptionProps?: any;
  autoFocus?: any;
}

const MultipleSelectSearch: FC<MultipleSelectSearchProps> = ({
  labelKeys = ['name'],
  valueKey = 'uuid',
  name,
  label,
  rules,
  api,
  className,
  initialOptions = [],
  initialOption,
  defaultFilter,
  labelCol,
  mode,
  style,
  formItemClassName,
  startingOptions = [],
  placeholder,
  render,
  size,
  onChange,
  defaultParams = {},
  disabled,
  onLoad,
  searchKey,
  allowClear,
  filterBeforeRender,
  renderOptionProps,
  autoFocus,
}) => {
  const [open, setOpen] = useState(undefined as any);
  const singleOption = [];
  if (initialOption) {
    singleOption.push(initialOption);
  }

  const [state, setState] = useState({
    loading: false,
    searching: false,
    data: [
      ..._.map(
        [...startingOptions, ...singleOption, ...initialOptions],
        (el) => ({
          ...el,
          value_copy: el?.value_copy ?? el?.value,
          label: el[labelKeys[0]],
          value: el[valueKey],
        })
      ),
    ] as any,
    search: null as any,
  });
  const { data, loading } = state;
  const [search, setSearch] = useState(null as any);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setState((prevState) => ({ ...prevState, loading: true }));
        const params: any = { ...defaultParams };
        if (search) {
          if (searchKey) {
            params[searchKey] = search;
          } else {
            labelKeys?.forEach((val, i) => {
              params[`search_term[${i}][field]`] = val;
              params[`search_term[${i}][rule]`] = 'contains';
              params[`search_term[${i}][value]`] = search;
            });
          }
        }

        if (defaultFilter && defaultFilter?.filterBy && defaultFilter?.value) {
          params[`filters[${defaultFilter?.filterBy}][]`] =
            defaultFilter?.value;
        }

        const res = await apiRequests.get(api, params);

        const getUniqData = (prevState: any) => {
          const newData = _.map(res?.data?.data, (el) => {
            let parsedLabel = '';
            labelKeys?.forEach((la) => {
              parsedLabel += `${_.get(el, la)} `;
            });

            return {
              ...el,
              value_copy: el?.value_copy ?? el?.value,
              label: parsedLabel,
              value: el[valueKey],
            };
          });
          const uniqData = _.uniqBy(
            [
              ..._.map([...startingOptions], (el) => {
                let parsedLabel = '';
                labelKeys?.forEach((la) => {
                  parsedLabel += `${el[la]} `;
                });

                return {
                  ...el,
                  value_copy: el?.value_copy ?? el?.value,
                  label: parsedLabel,
                  value: el[valueKey],
                };
              }),
              ...newData,
              ...prevState.data,
            ],
            'value'
          );

          if (onLoad) {
            onLoad(uniqData);
          }

          return uniqData;
        };

        setState((prevState) => ({
          ...prevState,
          loading: false,
          data: getUniqData(prevState),
        }));
      } catch (error) {
        asyncErrorHandler(error);
        setState((prevState) => ({ ...prevState, loading: false }));
      }
    };
    fetchData();
  }, [search, defaultFilter?.filterBy, defaultFilter?.value]);

  // useEffect(() => {
  //   if (open === false) {
  //     setTimeout(, 500);
  //   }
  // }, [open]);

  return (
    <Form.Item
      style={{
        // @ts-ignore
        position: 'relative !important',
      }}
      className={formItemClassName}
      label={label}
      labelCol={labelCol}
      name={name}
      rules={rules}
    >
      <Select
        autoFocus={autoFocus}
        onClick={() => {
          if (open === false) {
            setOpen(undefined);
          }
        }}
        open={open}
        allowClear={allowClear}
        dropdownStyle={{ position: 'fixed' }}
        className={className}
        disabled={disabled}
        filterOption={(input, el: any) =>
          el?.label?.toLowerCase().includes(input?.toLowerCase())
        }
        loading={loading}
        maxTagCount="responsive"
        mode={mode}
        onChange={(value) => {
          const res = Array.isArray(value)
            ? data?.filter((el: any) => _.includes(value, el.value))
            : data?.find((el: any) => el.value === value);
          if (onChange) onChange(res);
        }}
        onSearch={(value) => {
          setSearch(value);
        }}
        onSelect={() => {
          setSearch('');
        }}
        placeholder={placeholder}
        searchValue={search}
        showArrow
        showSearch
        size={size as any}
        style={style}
      >
        {(filterBeforeRender ? filterBeforeRender(data) : data)?.map(
          (el: any, i: any) => (
            <Select.Option
              {...(renderOptionProps ? renderOptionProps(el) : {})}
              key={i}
              label={el.label}
              value={el.value}
            >
              <Text strong={el.bold}>{render ? render(el) : el.label}</Text>
            </Select.Option>
          )
        )}
      </Select>
    </Form.Item>
  );
};

export default MultipleSelectSearch;
