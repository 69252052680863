import { trimUrl } from './stringHelpers';

const ENVIRONMENT = {
  IS_PRODUCTION: process.env.REACT_APP_IS_PRODUCTION === 'true',
  MAINTENANCE_MODE: process.env.REACT_APP_MAINTENANCE === 'yes',
  BASE_URL:
    process.env.REACT_APP_API_URL || 'https://api-dev.lionshare.app/api',
  ADMIN_URL: process.env.REACT_APP_ADMIN_URL,
  REACT_APP_UPLOADS_PATH:
    trimUrl(process.env.REACT_APP_UPLOADS_PATH) ??
    'https://lionshare-dev.s3.us-west-2.amazonaws.com/uploads',
  REACT_APP_PUSHER_CLUSTER: (process.env.REACT_APP_PUSHER_CLUSTER ??
    '') as string,
  REACT_APP_PUSHER_KEY: (process.env.REACT_APP_PUSHER_KEY ?? '') as string,
  SENTRY_DNS: process.env.REACT_APP_SENTRY_DNS,
  SENTRY_ENVIRONMENT: process.env.REACT_APP_SENTRY_ENVIRONMENT ?? 'local',
  SENTRY_RELEASE: process.env.REACT_APP_SENTRY_RELEASE,
  GOOGLE_RECAPTCHA_KEY: process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY ?? '',
};

export default ENVIRONMENT;
