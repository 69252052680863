import { Col, Form, Input, Modal } from 'antd';
import { FC } from 'react';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';

interface FeedbackModalProps {
  visible: boolean;
  onConfirm: any;
  onClose: any;
  loading: any;
}

const { TextArea } = Input;

const FeedbackModal: FC<FeedbackModalProps> = ({
  visible,
  onConfirm,
  onClose,
  loading,
}) => {
  const [form] = Form.useForm();

  return (
    <Modal
      destroyOnClose
      confirmLoading={loading}
      onCancel={onClose}
      open={visible}
      okButtonProps={{ shape: 'round' }}
      cancelButtonProps={{ shape: 'round' }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onConfirm(values);
          })
          .catch((error) => {
            asyncErrorHandler(error);
          });
      }}
    >
      <Form layout="vertical" form={form}>
        <Col xs={24}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
            className="mb-0"
            label="Comment"
            name="approval_feedback"
          >
            <TextArea
              placeholder="Please provide a feedback about the modifications"
              rows={5}
            />
          </Form.Item>
        </Col>
      </Form>
    </Modal>
  );
};

export default FeedbackModal;
