import { AxiosResponse } from 'axios';
import { call, put, StrictEffect, takeLatest } from 'redux-saga/effects';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';

import {
  getCampaigns,
  setCampaignsData,
  setCampaignsError,
  setCampaignsLoading,
} from './reducer';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';

const { get } = apiRequests;

function* getCampaignsSagaListener(action: any) {
  try {
    yield put(setCampaignsLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.PROJECTS, {
      'filters[projects.status]': [
        'paused',
        'waiting_to_launch',
        'in_progress',
        'active',
      ],
      'filters[advertising][]': 'true',
      'page[size]': 1,
      'page[number]': 1,
    });
    yield put(setCampaignsData(res.data));
  } catch (error) {
    yield put(setCampaignsError(error));
    asyncErrorHandler(error);
  }
}

function* CampaignsSaga(): Generator<StrictEffect> {
  yield takeLatest(getCampaigns.type, getCampaignsSagaListener);
}

export default CampaignsSaga;
