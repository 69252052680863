import {
  call,
  put,
  StrictEffect,
  takeEvery,
  select,
  takeLatest,
} from 'redux-saga/effects';

import {
  login,
  setAuthData,
  setAuthLoading,
  setLoginLoading,
  loadUser,
  updateAuthData,
  registerPushToken,
  updatePushToken,
  changeCustomer,
} from './reducer';
import apiRequests from '../../utils/api';
import apiRoutes from '../../utils/apiRoutes';
import { AxiosResponse } from 'axios';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import { PushTokensResponse } from 'src/types';

/**
 * worker saga
 */
const { post, get, put: putApi } = apiRequests;

export function* loginSagaListener(action: any): any {
  try {
    yield put(setLoginLoading(true));

    const res = yield call(post, apiRoutes.LOGIN, {
      cookie_login: false,
      ...action.payload,
    });

    yield put(setAuthData(res.data));
  } catch (error) {
    yield put(setLoginLoading(false));

    asyncErrorHandler(error);
  }
}

export function* loadUserSagaListener({
  payload,
}: {
  payload?: { onLoaded?: () => void };
  type: string;
}): any {
  const token = yield select((state) => state.auth.token);

  if (!token) {
    yield put(setAuthLoading(false));
    return;
  }

  try {
    yield put(setAuthLoading(true));

    const res: AxiosResponse = yield call(
      get,
      apiRoutes.USER_INFO,
      {},
      {},
      true
    );

    yield put(updateAuthData(res.data));

    payload?.onLoaded?.();
  } catch (error) {
    yield put(setAuthLoading(false));
  }
}

export function* changeCustomerSagaListener({
  payload,
}: {
  payload: string;
  type: string;
}): any {
  const user = yield select((state) => state.auth.user);

  yield put({ type: 'store/reset' });
  yield put(updateAuthData(null));
  yield put(setAuthLoading(true));

  try {
    yield call(putApi, `${apiRoutes.CONTACTS}/${user.contact?.uuid}`, {
      customer_id: payload,
    });
  } catch (error) {
    asyncErrorHandler(error);
  } finally {
    yield put(loadUser());
  }
}

export function* registerPushTokenListener({
  payload: newToken,
}: {
  payload: string;
  type: string;
}): any {
  const { push_tokens }: { push_tokens: PushTokensResponse[] | null } =
    yield select((state) => state.auth.user);
  const tokens = push_tokens ?? [];

  const prevTokenIndex = tokens.findIndex(
    (value) => value.token.split(':')[0] === newToken.split(':')[0]
  );

  try {
    if (prevTokenIndex > -1) {
      const prevToken = tokens[prevTokenIndex];

      yield call(putApi, `${apiRoutes.FCM_TOKEN}/${prevToken.token}`, {
        token: newToken,
      });

      const newTokens = [...tokens];

      newTokens[prevTokenIndex] = {
        ...newTokens[prevTokenIndex],
        token: newToken,
      };

      yield put(updatePushToken(newTokens));
    } else {
      const res: AxiosResponse = yield call(post, apiRoutes.FCM_TOKEN, {
        token: newToken,
      });

      yield put(updatePushToken([...tokens, res.data.data]));
    }
  } catch (error) {
    asyncErrorHandler(error);
  }
}

/**
 * watcher saga
 */
function* authSaga(): Generator<StrictEffect> {
  yield takeEvery(login.type, loginSagaListener);
  yield takeLatest(loadUser.type, loadUserSagaListener);
  yield takeLatest(changeCustomer.type, changeCustomerSagaListener);
  yield takeEvery(registerPushToken.type, registerPushTokenListener);
}

export default authSaga;
