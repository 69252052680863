import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Col, Popover, Row, Space, Typography } from 'antd';
import UserAvatar from '../UserAvatar';

const { Text } = Typography;

interface ReactionCounterProps {
  counters: any;
}

const ReactionCounter: FC<ReactionCounterProps> = ({ counters }) => {
  const { user } = useSelector((globalState: any) => globalState.auth);
  const currentUserReaction = counters?.find(
    (el: any) => el?.creator?.uuid === user?.uuid
  );

  const getReactionsString = () => {
    if (counters?.length === 1) {
      return (
        <Text>{`${
          currentUserReaction ? 'You' : counters?.[0]?.creator?.name
        }`}</Text>
      );
    }
    if (counters?.length === 2) {
      if (currentUserReaction) {
        return (
          <Text>
            <Text>You</Text>&nbsp;and&nbsp;
            <Text>
              {currentUserReaction?.creator?.uuid ===
              counters?.[1]?.creator?.uuid
                ? counters?.[0]?.creator?.name
                : counters?.[1]?.creator?.name}
            </Text>
          </Text>
        );
      }

      return (
        <Text>
          <Text>{`${counters?.[0]?.creator?.name}`}</Text>&nbsp;and&nbsp;
          <Text>{`${counters?.[1]?.creator?.name}`}</Text>
        </Text>
      );
    }

    if (currentUserReaction) {
      const filteredReactions = counters?.filter(
        (el: any) => el?.creator?.uuid !== user?.uuid
      );

      return (
        <div className="flex">
          {`You,  ${filteredReactions?.[0]?.creator?.name} and`}&nbsp;
          <Popover
            content={
              <Space direction="vertical">
                {filteredReactions?.slice(1)?.map((el: any, i: any) => {
                  return (
                    <Row wrap={false} gutter={20} align="middle" key={i}>
                      <Col span={6}>
                        <UserAvatar user={el?.creator} />
                      </Col>
                      <Col span={18}>
                        <Text ellipsis>{el?.creator?.name}</Text>
                      </Col>
                    </Row>
                  );
                })}
              </Space>
            }
          >
            <div className="pointer">{`${filteredReactions?.length - 1} other${
              filteredReactions?.length - 1 === 1 ? '' : 's'
            }`}</div>
          </Popover>
        </div>
      );
    }
    return (
      <div className="flex">
        {`${counters?.[0]?.creator?.name}, ${counters?.[1]?.creator?.name} and`}
        &nbsp;
        <Popover
          content={
            <Space direction="vertical">
              {counters?.slice(2)?.map((el: any, i: any) => {
                return (
                  <Row wrap={false} gutter={20} align="middle" key={i}>
                    <Col span={6}>
                      <UserAvatar user={el?.creator} />
                    </Col>
                    <Col span={18}>
                      <Text ellipsis>{el?.creator?.name}</Text>
                    </Col>
                  </Row>
                );
              })}
            </Space>
          }
        >
          <div className="pointer">{`${counters?.length - 2} other${
            counters?.length - 2 === 1 ? '' : 's'
          }`}</div>
        </Popover>
      </div>
    );
  };

  if (counters?.length === 0) {
    return null;
  }

  return (
    <div className="flex gap-[5px]">
      <img
        className="h-[15px] w-[15px]"
        src="/assets/like.png"
        alt="Like Icon"
      />
      <div className="text-[12px]">{getReactionsString()}</div>
    </div>
  );
};

export default ReactionCounter;
