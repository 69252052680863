import { Form } from 'antd';
import { FC } from 'react';
import { useStopwatch, useTimer } from 'react-timer-hook';
import { getDateDifferenceInMinutes } from 'src/utils/stringHelpers';

interface ApprovalCountDownProps {
  approval_target?: any;
  onCancel?: any;
}

const ApprovalCountDown: FC<ApprovalCountDownProps> = ({
  approval_target,
  onCancel,
}) => {
  const target = new Date(approval_target);

  const offsetTimestamp = new Date();
  offsetTimestamp.setMinutes(
    offsetTimestamp.getMinutes() + getDateDifferenceInMinutes(target.toString())
  );

  const timer = useTimer({
    expiryTimestamp: target,
    autoStart: true,
  });
  const time = useStopwatch({
    offsetTimestamp,
    autoStart: true,
  });

  const isExceeded = target < new Date(Date.now());

  return (
    <Form.Item className="mb-0" label="Approval time">
      <div
        className="task-timer"
        style={{
          border: '1px solid rgba(0, 68, 204, 0.2)',
          minHeight: '40.14px',
          color: '#FF0000',
          fontWeight: 700,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 10px',
        }}
      >
        <span style={{ fontSize: isExceeded ? '14px' : '18px' }}>
          {isExceeded
            ? `You are late ${time?.days} day(s)`
            : `${timer?.hours + timer.days * 24}:${timer?.minutes} Left`}
        </span>
      </div>
    </Form.Item>
  );
};

export default ApprovalCountDown;
