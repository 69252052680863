/* eslint-disable react-hooks/exhaustive-deps */
import { List, Skeleton } from 'antd';
import { IoFolderOpenOutline } from 'react-icons/io5';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Box from '../Box';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectsStateType, getProjects } from 'src/store/projects/reducer';
import StatusTag from '../StatusTag';
import { IoIosArrowForward } from 'react-icons/io';
import { RxQuestionMarkCircled } from 'react-icons/rx';
import { useAppTour } from '../../context/appTourContext';

interface ProjectType {
  loading?: boolean;
}

const Projects: React.FC<ProjectType> = ({ loading: loading1 }) => {
  const { setOpenTour } = useAppTour();
  const dispatch = useDispatch();
  const projects: ProjectsStateType = useSelector(
    (globalState: any) => globalState.projects
  );
  const { loading, data } = projects;

  useEffect(() => {
    dispatch(
      // @ts-ignore
      getProjects({
        'filters[projects.status]': [
          'paused',
          'waiting_to_launch',
          'in_progress',
          'active',
        ],
      })
    );
  }, []);

  return (
    <div>
      <div className="mb-2 flex gap-2 px-1 text-blue-600">
        <IoFolderOpenOutline className="text-2xl" />
        <span className="flex-auto font-bold">Projects</span>
        <button
          type="button"
          onClick={() => {
            setOpenTour(true, 8);
          }}
        >
          <RxQuestionMarkCircled className="text-2xl" />
        </button>
      </div>
      <Box>
        {(loading && !data) || loading1 ? (
          <>
            <Skeleton className="pt-[20px]" />
          </>
        ) : (
          <List
            itemLayout="horizontal"
            dataSource={data?.data}
            renderItem={(item) => (
              <List.Item key={item.uuid}>
                <Link
                  className="flex w-full justify-between"
                  to={`/projects/${item?.uuid}`}
                >
                  <p>{item?.title}</p>
                  <div className="whitespace-nowrap">
                    <StatusTag value={item?.status} />
                    <IoIosArrowForward className="ml-1 inline-block text-lg text-gray-600" />
                  </div>
                </Link>
              </List.Item>
            )}
          />
        )}
      </Box>
    </div>
  );
};

export default Projects;
