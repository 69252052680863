import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
};

export const dashboardProjectsSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardState: (state, action: any) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
  },
});

export const { setDashboardState } = dashboardProjectsSlice.actions;

export default dashboardProjectsSlice.reducer;
