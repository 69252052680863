import { createSlice } from '@reduxjs/toolkit';
import { ApiResponse, TaskResponse } from 'src/types';

export interface TasksStateType {
  loading: boolean;
  data: ApiResponse<TaskResponse> | null;
  error: null | string;
}

const initialState: TasksStateType = {
  loading: false,
  data: null,
  error: null,
};

export const dashboardTasksSlice = createSlice({
  name: 'dashboardTasks',
  initialState,
  reducers: {
    getDashboardTasks: () => {},
    setDashboardTasksLoading: (state) => ({ ...state, loading: true }),
    setDashboardTasksData: (state, action) => ({
      ...state,
      loading: false,
      data: action.payload,
    }),
    clearDashboardTasksData: () => initialState,
    setDashboardTasksError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
  },
});

export const {
  setDashboardTasksData,
  setDashboardTasksError,
  setDashboardTasksLoading,
  getDashboardTasks,
  clearDashboardTasksData,
} = dashboardTasksSlice.actions;

export default dashboardTasksSlice.reducer;
