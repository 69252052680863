/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProjectResponse } from 'src/types';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import axios, { AxiosError } from 'axios';
import asyncErrorHandler from '../../utils/asyncErrorHandler';

const useProject = () => {
  const [state, setState] = useState({
    loading: false,
    data: null as null | ProjectResponse,
    axiosError: null as AxiosError | null,
    updateTrigger: 0,
  });

  const params: any = useParams();
  const uuid = params?.uuid;
  const { updateTrigger } = state;

  const onUpdateTrigger = () => {
    setState((prevState) => ({
      ...prevState,
      updateTrigger: prevState.updateTrigger + 1,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setState((prevState) => ({
          ...prevState,
          loading: updateTrigger === 0,
        }));
        const res = await apiRequests.get(`${apiRoutes.PROJECTS}/${uuid}`);
        setState((prevState) => ({
          ...prevState,
          loading: false,
          data: res?.data?.data,
        }));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError;

          setState((old) => ({
            ...old,
            axiosError: axiosError,
            loading: false,
          }));

          if (axiosError.response?.status !== 404) {
            asyncErrorHandler(error);
          }
        } else {
          asyncErrorHandler(error);
        }
      }
    };

    fetchData();
  }, [updateTrigger]);

  return {
    state,
    onUpdateTrigger,
  };
};

export default useProject;
