import { TbMessages } from 'react-icons/tb';
import React, { useRef } from 'react';
import Box from '../Box';
import Comment from '../Comment';
import { useParams } from 'react-router-dom';
import apiRoutes from 'src/utils/apiRoutes';

interface ActivityProps {
  className?: string;
  onInitialLoaded: () => void;
}

const Activity: React.FC<ActivityProps> = ({
  onInitialLoaded,
  className = '',
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const params = useParams();

  const scrollToDiv = () => {
    if (!ref.current) return;

    ref.current.scrollIntoView();
  };

  return (
    <div ref={ref} className={`flex w-full scroll-mt-20 flex-col ${className}`}>
      <div className="flex w-full">
        <div className="flex items-center space-x-2 pb-4">
          <TbMessages
            className="block h-6 w-6 text-blue-600"
            aria-hidden="true"
          />
          <p className="text-[16px] font-bold text-blue-600">
            Recent activities
          </p>
        </div>
      </div>

      <Box noPadding noOverflow>
        <div className="px-2 pt-2">
          <Comment
            url={`${apiRoutes.PROJECTS}/${params.uuid}/comments`}
            projectUuid={params.uuid || ''}
            onInitialLoaded={onInitialLoaded}
            onCommentsLoaded={scrollToDiv}
            containerInputClass="px-2"
            showCommentType
            compact
          />
        </div>
      </Box>
    </div>
  );
};

export default Activity;
