import { ScrollRestoration } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AuthStateType } from 'src/store/auth/reducer';
import Booking from './index';

const BookingRoute = () => {
  const auth: AuthStateType = useSelector(
    (globalState: any) => globalState.auth
  );

  if (auth.user) {
    return (
      <div className="mt-6 md:mt-16">
        <ScrollRestoration />
        <Booking />
      </div>
    );
  }

  return (
    <div className="bg-white">
      <ScrollRestoration />
      <Booking showHomeButton />
    </div>
  );
};

export default BookingRoute;
