import { createSlice } from '@reduxjs/toolkit';
import { ApiResponse, TaskResponse } from 'src/types';

export interface TasksStateType {
  loading: boolean;
  data: ApiResponse<TaskResponse> | null;
  error: null | string;
}

const initialState: TasksStateType = {
  loading: false,
  data: null,
  error: null,
};

export const pendingTasksSlice = createSlice({
  name: 'pendingTasks',
  initialState,
  reducers: {
    getPendingTasks: () => {},
    setPendingTasksLoading: (state) => ({ ...state, loading: true }),
    setPendingTasksData: (state, action) => ({
      ...state,
      loading: false,
      data: action.payload,
    }),
    clearPendingTasksData: () => initialState,
    setPendingTasksError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
  },
});

export const {
  setPendingTasksData,
  setPendingTasksError,
  setPendingTasksLoading,
  getPendingTasks,
  clearPendingTasksData,
} = pendingTasksSlice.actions;

export default pendingTasksSlice.reducer;
