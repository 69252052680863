import React, { FC } from 'react';

interface BoxProps {
  children?: React.ReactNode;
  noPadding?: boolean;
  transparent?: boolean;
  noOverflow?: boolean;
}

const Box: FC<BoxProps> = ({
  children,
  noPadding,
  noOverflow,
  transparent,
}) => {
  return (
    <div
      className={`h-full min-h-[300px] w-full rounded-2xl ${
        noOverflow ? '' : 'overflow-auto'
      } ${transparent ? '' : 'bg-white'} shadow-xl shadow-blue-500/20 ${
        noPadding ? '' : 'px-6 py-2'
      }`}
    >
      {children}
    </div>
  );
};

export default Box;
