import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Input, message, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import RichTextEditorForm from 'src/components/RichTextEditor/RichTextEditorForm';
import { updateAuthData } from 'src/store/auth/reducer';
import { UploadFileApi, UserResponse } from 'src/types';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';

const CompanyProfile: FC = () => {
  const user: UserResponse = useSelector(
    (globalState: any) => globalState.auth.user
  );
  const [form] = useForm();
  const dispatch = useDispatch();

  const [noteImages, setNoteImages] = useState<UploadFileApi[]>([]);
  const [submitLoading, setSubmitLoading] = useState(false);

  const customer = user.contact.customer;

  const formInitialState = {
    business_name: customer.business_name,
    billing_email: customer.billing_email,
    phone: customer.phone,
    website: customer.website,
    address_line1: customer.address_line1,
    city: customer.city,
    state: customer.state,
    zip: customer.zip,
    notes: customer.notes,
  };

  const submitHandler = async (values: any) => {
    setSubmitLoading(true);

    try {
      const { notes }: { notes: string | undefined } = values;

      const uploads = notes
        ? noteImages.filter((x) => x.url && notes.includes(`src="${x.url}"`))
        : [];

      const response = await apiRequests.put(
        `${apiRoutes.CUSTOMERS}/${customer.uuid}`,
        {
          ...values,
          uploads: uploads.map((x) => x.uid),
        }
      );

      if (notes) {
        setNoteImages((old) =>
          old.filter((x) => x.url && !uploads.includes(x))
        );
      }

      dispatch(
        updateAuthData({
          ...user,
          contact: {
            ...user.contact,
            customer: response.data.data,
          },
        })
      );

      message.success('Company profile updated.');
    } catch (error) {
      asyncErrorHandler(error);
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <div>
      <div className="mb-6">
        <h2 className="mb-2 text-2xl text-blue-700">Company profile</h2>
      </div>

      <Form
        form={form}
        labelCol={{ style: { width: '120px' } }}
        className="default-form"
        initialValues={formInitialState}
        onFinish={submitHandler}
      >
        <Row gutter={8} className="mb-6">
          <Col xs={24} md={12}>
            <Form.Item
              label="Business name"
              name="business_name"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Email"
              name="billing_email"
              rules={[
                {
                  required: true,
                  message: 'This field must be a valid email',
                  type: 'email',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Phone" name="phone">
              <Input />
            </Form.Item>

            <Form.Item label="Website" name="website">
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item label="Address" name="address_line1">
              <Input />
            </Form.Item>

            <Form.Item label="City" name="city">
              <Input />
            </Form.Item>

            <Form.Item label="State" name="state">
              <Input />
            </Form.Item>

            <Form.Item label="Zipcode" name="zip">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="notes">
          <div className="ant-form-item-label mb-2">
            <label>Notes</label>
          </div>

          <RichTextEditorForm
            form={form}
            name="notes"
            includeToolbar
            onImageUploader={(upload) => {
              setNoteImages((prev) => [...prev, upload]);
            }}
          />
        </Form.Item>

        <Button
          htmlType="submit"
          type="primary"
          size="large"
          className="mt-6"
          loading={submitLoading}
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default CompanyProfile;
