import { useEffect, useState } from 'react';
import { feedbackIntegration } from '@sentry/react';
import { FloatButton, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import { TbBook, TbBug, TbBulb, TbPlus, TbMessage2Star } from 'react-icons/tb';
import { getProjects, ProjectsStateType } from 'src/store/projects/reducer';
import { useAppTour } from 'src/context/appTourContext';

const sentryFeedback = feedbackIntegration({
  colorScheme: 'system',
  autoInject: false,
});

const sentryWidget = sentryFeedback.createWidget();

sentryWidget.el.style.display = 'none';

const FloatActions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { setOpenTour } = useAppTour();

  const [openTooltip, setOpenTooltip] = useState(false);
  const [openFloatGroup, setOpenFloatGroup] = useState(false);
  const [timeoutTooltip, setTimeoutTooltip] = useState<number>();

  const projects: ProjectsStateType = useSelector(
    (globalState: any) => globalState.projects
  );

  useEffect(() => {
    dispatch(
      // @ts-ignore
      getProjects({
        'filters[projects.status]': [
          'paused',
          'waiting_to_launch',
          'in_progress',
          'active',
        ],
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (!isMobile) return;

    if (!openFloatGroup) {
      clearTimeout(timeoutTooltip);
    }

    return () => clearTimeout(timeoutTooltip);
  }, [openFloatGroup, timeoutTooltip]);

  const floatButtonOpenHandler = (open: boolean) => {
    setOpenFloatGroup(open);

    if (!isMobile) return;

    if (open) {
      const timeout = setTimeout(() => {
        setOpenTooltip(true);
      }, 350) as unknown as number;

      setTimeoutTooltip(timeout);
      return;
    }

    setOpenTooltip(false);
  };

  return (
    <FloatButton.Group
      open={openFloatGroup}
      trigger={isMobile ? 'click' : 'hover'}
      type="primary"
      style={{ right: 24 }}
      icon={<TbPlus />}
      onOpenChange={floatButtonOpenHandler}
    >
      <Tooltip
        title="Start Tour"
        placement="leftTop"
        open={isMobile ? openTooltip : undefined}
      >
        <FloatButton
          onClick={() => {
            if (location.pathname !== '/dashboard') {
              navigate('/dashboard');
            }
            setOpenTour(true);
          }}
          icon={<TbBook />}
        />
      </Tooltip>

      <Tooltip
        title="Review lionshare"
        placement="leftTop"
        open={isMobile ? openTooltip : undefined}
      >
        <FloatButton
          onClick={() =>
            window.open(
              'https://savageglobalmarketing.typeform.com/to/syFEQMWH?typeform-source=admin.lionshare.app'
            )
          }
          icon={<TbMessage2Star />}
        />
      </Tooltip>

      <Tooltip
        title="Report a bug"
        placement="leftTop"
        open={isMobile ? openTooltip : undefined}
      >
        <FloatButton
          onClick={() => {
            sentryWidget.el.click();
            // throw new SentryReportBug();
          }}
          icon={<TbBug />}
        />
      </Tooltip>

      <Tooltip
        title="Submit a suggestion"
        placement="leftTop"
        open={isMobile ? openTooltip : undefined}
      >
        <FloatButton
          onClick={() =>
            window.open('https://lionshare.canny.io/feature-request')
          }
          icon={<TbBulb />}
        />
      </Tooltip>

      {!projects.data || projects.data.data.length ? (
        <Tooltip
          title="Create a new task"
          placement="leftTop"
          open={isMobile ? openTooltip : undefined}
        >
          <FloatButton
            onClick={() => {
              setOpenFloatGroup(false);
              setOpenTooltip(false);
              location.pathname.includes('project')
                ? navigate(`${location.pathname}?task_id=create`, {
                    preventScrollReset: true,
                  })
                : navigate('/dashboard?task_id=create', {
                    preventScrollReset: location.pathname.includes('dashboard'),
                  });
            }}
          />
        </Tooltip>
      ) : undefined}
    </FloatButton.Group>
  );
};

export default FloatActions;
