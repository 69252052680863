import React from 'react';
import { TbExternalLink } from 'react-icons/tb';

interface CardProps {
  name: string;
  category: string;
  snippet: string;
  link: string;
  imageUrl: string;
}

const Card: React.FC<CardProps> = ({
  name,
  category,
  snippet,
  link,
  imageUrl,
}) => {
  return (
    <div className="mb-4 flex h-full flex-col rounded-2xl bg-white p-4">
      <img
        className="h-full w-full rounded border border-indigo-100 sm:max-h-[200px]"
        src={imageUrl}
        alt={`${name}'s banner`}
      />
      <div className="flex h-full flex-col justify-between pt-4">
        <div>
          <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
            {name}
          </h3>
          <p className="text-sm leading-6 text-gray-400">{category}</p>
          <p className="pb-4 text-sm leading-6 text-gray-700">{snippet}</p>
        </div>
        <a
          href={link}
          rel="noreferrer"
          target="_blank"
          className="block rounded-md border border-[#0044cc] px-3.5 py-2.5 text-center text-sm shadow-sm hover:bg-indigo-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#0044cc]"
        >
          Learn more{' '}
          <TbExternalLink
            className="-mt-1 inline h-4 w-4 text-[#0044cc]"
            aria-hidden="true"
          />
        </a>
      </div>
    </div>
  );
};

export default Card;
