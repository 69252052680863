import { useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  Alert,
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Typography,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import RichTextEditorForm from 'src/components/RichTextEditor/RichTextEditorForm';
import UploadDragger from 'src/components/FileUpload/UploadDragger';
import { removeImage } from 'src/utils/stringHelpers';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import { UploadFileApi } from 'src/types';
import dayjs, { Dayjs } from 'dayjs';

const { Title } = Typography;

const descriptionPlaceholder =
  'Describe in detail what your requirements and needs are. ' +
  'The more descriptive you are, the easier it is for us to get it done. If we need any help, we will get back with you.\n\n' +
  'Please provide the link to your Website/Figma/Dropbox assets if needed.\n\n' +
  "Describe in detail what your goal is. For example: I'd like to have a creative blue flyer that captures " +
  "the audience of 50+ males OR I'm looking to spend $300 per day to drive new customers to our dealership.";

const RequestPage = () => {
  const [form] = useForm();
  const [formSentSuccess, setFormSentSuccess] = useState(false);
  const [hasFileLoading, setHasFileLoading] = useState(false);
  const [loadingImages, setLoadingImages] = useState(0);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [uploads, setUploads] = useState<UploadFileApi[]>([]);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const disableTargetDate = (current: Dayjs) => {
    const dayjsNow = dayjs().startOf('day');
    const day = dayjsNow.day();
    const minSpaceDays = 2;

    if (day === 0) {
      return current.subtract(minSpaceDays + 1, 'days').isBefore(dayjsNow);
    }

    if (day === 6 || day === 5 || day === 4) {
      return current.subtract(minSpaceDays + 2, 'days').isBefore(dayjsNow);
    }

    return current.subtract(minSpaceDays, 'days').isBefore(dayjsNow);
  };

  useEffect(() => {
    const submitHandler = async () => {
      if (!executeRecaptcha) {
        message.info('Loading recaptcha, please try again');
        return;
      }

      const values = await form.validateFields();

      values.uploads = uploads.map((item) => item.response?.uuid);

      values.target_date = values.target_date?.format('YYYY-MM-DD');

      try {
        values.recaptcha_token = await executeRecaptcha();
      } catch (error) {
        message.error('Problem with recaptcha, please try again');
        return;
      }

      try {
        await apiRequests.post(apiRoutes.PROJECT_REQUEST, values);

        form.resetFields();

        setUploads([]);

        setFormSentSuccess(true);
      } catch (error) {
        asyncErrorHandler(error);
      }
    };

    if (!hasFileLoading && !loadingImages && submitLoading) {
      submitHandler().finally(() => setSubmitLoading(false));
    }
  }, [
    executeRecaptcha,
    form,
    hasFileLoading,
    loadingImages,
    submitLoading,
    uploads,
  ]);

  return (
    <div className="min-h-screen bg-[#FAFAFA] pt-10">
      <div className="mx-auto max-w-4xl py-16">
        {!formSentSuccess ? (
          <>
            <Title level={1} className="text-center !text-blue-700">
              Form Request
            </Title>

            <Form
              className="default-form"
              layout="vertical"
              form={form}
              size="large"
              onFinish={() => setSubmitLoading(true)}
            >
              <Form.Item
                name="from_email"
                label="Your email"
                rules={[{ required: true, message: 'Please enter your email' }]}
              >
                <Input bordered={false} />
              </Form.Item>

              <Form.Item
                name="company_name"
                label="Company name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the name of your company',
                  },
                ]}
              >
                <Input bordered={false} />
              </Form.Item>

              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the description of your request',
                  },
                ]}
              >
                <RichTextEditorForm
                  form={form}
                  name="description"
                  placeholder={descriptionPlaceholder}
                  includeToolbar
                  onImageUploader={(upload) => {
                    setUploads((prev) => [...prev, upload]);
                  }}
                  onLoadingImage={setLoadingImages}
                />
              </Form.Item>

              <UploadDragger
                maxSize="50MB"
                uploads={uploads}
                setLoading={setHasFileLoading}
                setUploads={setUploads}
                showDownloadIcon
                showRemoveIcon
                onRemove={(upload) => {
                  const newText = removeImage(
                    form.getFieldValue('description'),
                    upload.response?.url || ''
                  );
                  form.setFieldValue('description', newText);
                }}
              />

              <div className="mb-4 mt-1 text-xs text-gray-400">
                If you need to upload a lot of files, ask our team for a special
                link where you can upload unlimited files of any size.
              </div>

              <Form.Item
                name="target_date"
                label="Set a due date - Minimum request time 48h"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the due date of the request',
                  },
                ]}
                extra={
                  <div className="mt-1 text-xs text-gray-400">
                    If you need it to be urgent, just submit the form and send
                    an email to support@savageglobalent.como asking to speed up
                    this request.
                  </div>
                }
              >
                <DatePicker
                  className="w-52"
                  showToday={false}
                  format="MM/DD/YYYY"
                  disabledDate={disableTargetDate}
                />
              </Form.Item>

              <div className="mb-2 text-xs text-gray-400">
                If your email is not found in our system, you will receive an
                email asking you to register a user so that you can receive
                notifications and follow the progress of your request.
              </div>

              <div className="mx-auto max-w-[160px]">
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={submitLoading}
                  block
                >
                  Submit
                </Button>
              </div>
            </Form>
          </>
        ) : (
          <Alert
            message="Form request sent successful"
            description={
              <div>
                <div className="mb-2">
                  Roaring good news! Request received. Check Lionshare for
                  updates within 24-48 hours.
                </div>
                <Button
                  type="primary"
                  onClick={() => setFormSentSuccess(false)}
                >
                  Send another request
                </Button>
              </div>
            }
            type="success"
            showIcon
          />
        )}
      </div>
    </div>
  );
};

export default RequestPage;
