/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react';

import {
  Checkbox,
  Divider,
  Form,
  Input,
  Select,
  Typography,
  DatePicker,
} from 'antd';

import { parseDateToUSFormat } from 'src/utils/stringHelpers';
import dayjs from 'dayjs';

const { Title, Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

export interface FormBlockProps {
  state: any;
  form?: any;
  initState?: any;
  disabled?: boolean;
}

const FormBlock: React.FC<FormBlockProps> = ({
  state,
  form,
  initState,
  disabled,
}) => {
  return (
    <div className={`form-view w-full`}>
      <div className="show-in-print mb-[20px]">
        <Text className="!text-blue-500">
          Project: {state?.data?.project?.title}
        </Text>
        <br />
        <Text className="!text-blue-500">
          Company : {state?.data?.project?.customer_business_name}
        </Text>
      </div>
      <Title className="mb-[10px]" level={3}>
        {state.title}
      </Title>
      <span>{state.description}</span>
      <div className="mb-[30px]" />
      <Form
        size="large"
        className="blue-labels default-form"
        form={form}
        initialValues={initState}
        layout="vertical"
      >
        {state?.questions?.map((item: any) => {
          if (item.type === 'Header') {
            return (
              <div key={item.id}>
                <div className="mt-[50px] text-[17px]">{item.label}</div>
                <Divider />
              </div>
            );
          }

          if (item.type === 'Short') {
            return (
              <div key={item.id}>
                <Form.Item
                  className="hide-in-print"
                  label={item.label}
                  name={item.id}
                  required={item.required}
                  rules={[
                    {
                      required: item.required,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <Input readOnly={disabled} />
                </Form.Item>
                <Form.Item
                  className="show-in-print"
                  label={item.label}
                  name={item.id}
                >
                  <div>{initState?.[item?.id]}</div>
                </Form.Item>
              </div>
            );
          }
          if (item.type === 'Paragraph') {
            return (
              <div key={item.id}>
                <Form.Item
                  className="hide-in-print"
                  label={item.label}
                  name={item.id}
                  required={item.required}
                  rules={[
                    {
                      required: item.required,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <TextArea readOnly={disabled} rows={5} />
                </Form.Item>
                <Form.Item
                  className="show-in-print"
                  label={item.label}
                  name={item.id}
                >
                  <div>{initState?.[item?.id]}</div>
                </Form.Item>
              </div>
            );
          }
          if (item.type === 'Dropdown') {
            return (
              <div key={item.id}>
                <Form.Item
                  className="hide-in-print"
                  label={item.label}
                  name={item.id}
                  required={item.required}
                  rules={[
                    {
                      required: item.required,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <Select className={disabled ? 'read-only' : undefined}>
                    {state?.questions?.map((el: any, ind: number) => {
                      if (el.parent === item.id) {
                        return (
                          <Option key={ind} value={el.label}>
                            {el.label}
                          </Option>
                        );
                      }
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  className="show-in-print"
                  label={item.label}
                  name={item.id}
                >
                  <div>{initState?.[item?.id]}</div>
                </Form.Item>
              </div>
            );
          }
          if (item.type === 'Checkbox') {
            const res = state?.questions?.filter((el: any) => {
              if (el.parent === item.id) {
                return true;
              }
            });

            return (
              <div key={item.id}>
                <Form.Item
                  className="hide-in-print"
                  label={item.label}
                  name={item.id}
                  required={item.required}
                  rules={[
                    {
                      required: item.required,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <Checkbox.Group
                    className={disabled ? 'read-only' : undefined}
                    options={res.map((el: any) => ({
                      label: el.label,
                      value: el.label,
                    }))}
                  />
                </Form.Item>
                <Form.Item
                  className="show-in-print"
                  label={item.label}
                  name={item.id}
                >
                  <div>{initState?.[item?.id]}</div>
                </Form.Item>
              </div>
            );
          }
          if (item.type === 'Date') {
            return (
              <div key={item.id}>
                <Form.Item
                  className="hide-in-print"
                  label={item.label}
                  name={item.id}
                  required={item.required}
                  rules={[
                    {
                      required: item.required,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <DatePicker
                    className={disabled ? 'read-only' : undefined}
                    format="MM/DD/YYYY"
                    value={dayjs()}
                  />
                </Form.Item>
                <Form.Item
                  className="show-in-print"
                  label={item.label}
                  name={item.id}
                >
                  <div>{parseDateToUSFormat(initState?.[item?.id])}</div>
                </Form.Item>
              </div>
            );
          }
        })}
      </Form>
    </div>
  );
};

export default FormBlock;
