import auth from './auth/reducer';
import tasks from './tasks/reducer';
import dashboardTasks from './dashboardTasks/reducer';
import pendingTasks from './pendingTasks/reducer';
import pendingForms from './pendingForms/reducer';
import projects from './projects/reducer';
import dashboardProjects from './dashboardProjects/reducer';
import inbox from './inbox/reducer';
import activities from './activities/reducer';
import campaigns from './campaigns/reducer';
import dashboard from './dashboard/reducer';

const combinedReducer = {
  auth,
  tasks,
  projects,
  inbox,
  dashboardProjects,
  dashboardTasks,
  activities,
  pendingTasks,
  campaigns,
  pendingForms,
  dashboard,
};

export default combinedReducer;
