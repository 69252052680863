import { AxiosResponse } from 'axios';
import { call, put, StrictEffect, takeLatest } from 'redux-saga/effects';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';

import {
  getPendingTasks,
  setPendingTasksData,
  setPendingTasksError,
  setPendingTasksLoading,
} from './reducer';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';

const { get } = apiRequests;

function* getPendingTasksSagaListener(action: any) {
  try {
    yield put(setPendingTasksLoading());
    const res: AxiosResponse = yield call(
      get,
      apiRoutes.PROJECT_TASKS,
      action.payload
    );

    yield put(setPendingTasksData(res.data));
  } catch (error) {
    yield put(setPendingTasksError(error));
    asyncErrorHandler(error);
  }
}

function* pendingTasksSaga(): Generator<StrictEffect> {
  yield takeLatest(getPendingTasks.type, getPendingTasksSagaListener);
}

export default pendingTasksSaga;
