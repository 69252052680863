import React from 'react';
import Card from '../../components/Card';

interface Resource {
  name: string;
  category: string;
  snippet: string;
  link: string;
  imageUrl: string;
}

const resources: Resource[] = [
  {
    name: 'Ad graphics size guide',
    category: 'Content & Social Media',
    snippet: 'Call tracking, recording, and more.',
    link: 'https://www.dropbox.com/s/xtds638jndslkny/Ad-Sizes-SGM-opt.pdf?dl=0',
    imageUrl: '/assets/AdSizes.png',
  },
  {
    name: 'CallRail',
    category: 'Call Tracking',
    snippet: 'Call tracking, recording, and more.',
    link: 'https://www.callrail.com/pricing?gspk=Y2hyaXN0b3BoZXJzYXZhZ2UxNDU5&gsxid=wm03X651D3Ud&pscd=partners.callrail.com&utm_campaign=partnerstack&utm_medium=agency_referral&utm_source=christophersavage1459',
    imageUrl: '/assets/CallRail.png',
  },
  {
    name: 'Cloud Campaign',
    category: 'Content & Social Media',
    snippet: 'Social media posting, analytics, and management software.',
    link: 'https://cloudcampaign.io/?fp_ref=christopher55',
    imageUrl: '/assets/CloudCampaign.png',
  },
  {
    name: 'Constant Contact Email Marketing',
    category: 'Email marketing',
    snippet:
      "Email marketing, campaign management, and more (we've been a partner since we opened our doors in 2014).",
    link: 'https://www.constantcontact.com/signup?pn=savageglobalmarketing',
    imageUrl: '/assets/ConstantContact.png',
  },
  {
    name: 'GoHighLevel',
    category: 'Sales & CRM',
    snippet:
      'Sales management, automated follow-ups, and sales workflows to close deals.',
    link: 'https://www.gohighlevel.com/annual?fp_ref=savage-global-marketing94',
    imageUrl: '/assets/GoHighLevel.png',
  },
  {
    name: 'Grammarly',
    category: 'Grammar assistance',
    snippet: 'Free online writing assistant.',
    link: 'https://app.grammarly.com/',
    imageUrl: '/assets/Grammarly.png',
  },
  {
    name: 'Grant access to advertising/social',
    category: 'Content & Social Media',
    snippet:
      'The easiest way to provide access to Google, Facebook & TikTok assets.',
    link: 'https://app.leadsie.com/connect/sgm/manage',
    imageUrl: '/assets/Leadsie.png',
  },
  {
    name: 'Grant access to social media',
    category: 'Content & Social Media',
    snippet:
      'The easiest way to provide access to Google, Facebook & TikTok assets.',
    link: 'https://app.leadsie.com/connect/request/64677cdf6fdeb8ba93ec2ee2',
    imageUrl: '/assets/Leadsie.png',
  },
  {
    name: 'PandaDoc – Create, Approve, Track & eSign Docs Faster',
    category: 'Sales & CRM',
    snippet: 'Create proposals, e-sign documents, and forms.',
    link: 'https://www.pandadoc.com/',
    imageUrl: '/assets/PandaDoc.png',
  },
  {
    name: 'Rytr',
    category: 'Content & Social Media',
    snippet: '10x faster way to write, optimizing content with AI.',
    link: 'https://rytr.me/?via=savage-global-marketing',
    imageUrl: '/assets/Rytr.png',
  },
  {
    name: 'SGM Welcome Kit',
    category: 'Welcome Kit',
    snippet: 'Download your welcome kit.',
    link: 'https://www.dropbox.com/s/h07zz8d8kwopquy/WelcomeDeck.pdf?dl=0',
    imageUrl: '/assets/WelcomeSGM.png',
  },
  {
    name: 'Studio Karvisual',
    category: 'Studio',
    snippet: 'Multi Set Studio.',
    link: 'https://karvisual.com/studio-rental/',
    imageUrl: '/assets/KarvVisual.png',
  },
  {
    name: 'Studio 24/7 Peerspace Miami, FL',
    category: 'Studio',
    snippet:
      '24/7 Pro Studio w/ Cyc Wall + Multi Sets + Gear + Last Minute Booking(Studio C).',
    link: 'https://www.peerspace.com/pages/listings/5fc96725b646f8000cfa6feb?sort_order=1',
    imageUrl: '/assets/Peerspace.png',
  },
  {
    name: 'Voiceover (Male/Female) - English',
    category: 'Voice Over',
    snippet: 'English voice over for videos',
    link: 'https://lionshare-prd.s3.us-west-2.amazonaws.com/cms/ENGLISH_VO_SAMPLES_44113d3a8a.mov',
    imageUrl: '/assets/Voiceover.png',
  },
  {
    name: 'Voiceover (Male/Female) - Spanish',
    category: 'Studio',
    snippet: 'Spanish Voice over for videos',
    link: 'https://lionshare-prd.s3.us-west-2.amazonaws.com/cms/SPANISH_VO_0717b9459b.mov',
    imageUrl: '/assets/Voiceover.png',
  },
];
const ClientResource = () => {
  return (
    <div>
      <div>
        <div className="mb-8 space-y-4 lg:mb-8">
          <p className="text-[22px] font-light text-blue-700 lg:text-[28px]">
            Resources
          </p>
          <p className="max-w-3xl">
            We want to provide you with some resources that have been useful in
            our work over the past decade. We can't guarantee success, but most
            of the time it has been great!
          </p>
          <p className="font-bold text-blue-700 ">
            Tips to keep your business always moving forward:
          </p>
          <ul className="pl-4">
            <li>
              - If you have a social media profile, stay active on it or delete
              it.
            </li>
            <li>
              - Aim to do 3-5 posts per week, and remember that one post is
              better than none.
            </li>
            <li>
              - Always try to get reviews from your customers for Google, Amazon
              G2, Yelp, and other platforms.
            </li>
            <li>
              - Be kind to your customers and partners; they are your biggest
              influencers.
            </li>
          </ul>
        </div>
      </div>
      <div className="grid grid-flow-row gap-4 sm:grid-cols-2 lg:grid-cols-3">
        {resources.map((resource) => (
          <Card
            key={resource.name}
            name={resource.name}
            category={resource.category}
            snippet={resource.snippet}
            link={resource.link}
            imageUrl={resource.imageUrl}
          />
        ))}
      </div>
    </div>
  );
};

export default ClientResource;
